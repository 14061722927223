import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { StorageService } from '../../common/services/storage.service';
import { AuthService } from '../../common/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  userData: any;

  constructor(public http: HttpClient,
    private storage: StorageService,
    private auth: AuthService) { }

  getList(request: any): any {
    return {
      'data': [
        { 'code': '00001', 'name': 'Mohandas PM', 'group': 'admin', 'card': '00001' },
        { 'code': '00002', 'name': 'Jojesh Jose', 'group': 'admin', 'card': '00002' },
        { 'code': '00003', 'name': 'Sandhya KV', 'group': 'user', 'card': '00003' },
        { 'code': '00004', 'name': 'Reshma Raj', 'group': 'user', 'card': '00004' },
        { 'code': '00005', 'name': 'Dhanya ', 'group': 'user', 'card': '00005' },

        { 'code': '00006', 'name': 'Lojo Paul', 'group': 'user', 'card': '00006' },
        { 'code': '00001', 'name': 'Mohandas PM', 'group': 'admin', 'card': '00001' },
        { 'code': '00002', 'name': 'Jojesh Jose', 'group': 'admin', 'card': '00002' },
        { 'code': '00003', 'name': 'Sandhya KV', 'group': 'user', 'card': '00003' },
        { 'code': '00004', 'name': 'Reshma Raj', 'group': 'user', 'card': '00004' },

      ]
    };
  }
  /**
   *Get shop list 
   * @returns 
   */
  getShopList() {
    return this.http.get(environment.dataApiBaseUrl + '/login/get-shop-list');
  }


  /**
  *get user last login time
  * 
  */
  updateLastlogin(userDetails: any ){
    return this.http.post<any>(environment.dataApiBaseUrl + "/login/update-lastlogin", JSON.stringify(userDetails));
  }
  /**
  *user login
  * @returns loginDetails
  */
  userLogin(loginDetails: any) {
    return this.http.post<any>(environment.dataApiBaseUrl + '/login/user-login', JSON.stringify(loginDetails), { headers: { 'Anonymous': '' } })
      .pipe(map(response => {
        this.auth.validateToken(response.token);
        this.userData = response.data;
        this.storage.saveToSession('user', JSON.stringify(this.userData));
        return response;
      }));
  }
}
