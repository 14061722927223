import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NgxSpinnerService } from 'ngx-spinner';

import { OrderApiService } from '../../order/order-api.service';
import { PauseOrderConfirmComponent } from '../pause-order-confirm/pause-order-confirm.component';
import moment from 'moment';
import { formatDate } from '@angular/common';

import { PauseOrderResumeComponent } from '../pause-order-resume/pause-order-resume.component';


import { CalendarService } from '../../calendar/calendar.service';
import { SideNavService } from '../../../side-nav/side-nav.service';
import { StorageService } from '../../../common/services/storage.service';
import { TimerService } from '../../../common/services/timer.service';
import { OnActionExport } from '../../../side-nav/side-nav.component';
import { NotificationService } from '../../../common/services/notification.service';

export interface ScopData {
  offset: number,
  limit: number
}

export interface PagingData {
  cur_page: number,
  limit: number,
  rows: number,
  pages?: any,
  offset: number
};
/**
 * Data structure for Sort data
 */
export interface SortData {
  column: String,
  order: String
};
export interface ColFilter {
  column: String,
  operator: String,
  value: any,

}

/**
 * Data structure for request
 */
export interface RequestData {
  shop_id: any,
  search?: {
    filters?: {
      filter?: ColFilter[],
      scope?: ScopData,
    }
    sort?: SortData[]
  }
};

@Component({
  selector: 'app-pause-orders-list',
  templateUrl: './pause-orders-list.component.html',
  styleUrls: ['./pause-orders-list.component.scss'],
  encapsulation: ViewEncapsulation.None  
})
export class PauseOrdersListComponent implements OnInit, OnActionExport, OnDestroy {
  startDate = new Date(1990, 0, 1);
  @Input() pageData: PagingData;
  userList: any;
  pauseOrderList: any;
  shop_id: any;
  sort: SortData = { column: 'date', order: 'asc' };
  scop?: ScopData;
  filters?: [];
  timeFrom: any;
  timeTo: any;
  firstDaydate: any;
  searchData: { shop_id: string, search: { filters: { filter: { column: string; operator: string; value: any; }[]; scope: { offset: number; limit: number; }; }; sort: { column: string; order: string; }[]; }; };
  datecase: string;
  orderPaused_Status: boolean;
  holiday_status: any;
  pausedTime: any;
  userId: any;
  hasError: boolean;
  navBtnStatus: any;
  from_time: any;
  to_time: any;
  getFromDate: any;
  timer: any;
  constructor(private sideNavService: SideNavService, public dialog: MatDialog,
    private orderApiService: OrderApiService,private CalendarService: CalendarService, private spinner: NgxSpinnerService,
    private storage: StorageService, private timerService: TimerService, private notifyService: NotificationService,) {
    sideNavService.set(this, "Pause Online Sales",true);
    const user = JSON.parse(this.storage.loadFromSession('user'));
    this.shop_id = user.shop_id;
    this.userId = user.user_id;
    this.orderPaused_Status = false;
    this.hasError = false;
    this.timeTo = new Date();
    var date = new Date();
    this.timeFrom = new Date(date.getFullYear(), date.getMonth(), 1);
    this.datecase = '"' + moment(this.timeFrom).format('YYYY-MM-DD') + '"  and "' + moment(this.timeTo).format('YYYY-MM-DD') + '"';
    this.pageData = { cur_page: 1, limit: 10, rows: 2, pages: 20, offset: 1 }

    this.searchData = {
      "shop_id": this.shop_id,
      "search": {
        "filters": {
          "filter": [

            {
              "column": "date",
              "operator": "BETWEEN",
              "value": this.datecase
            }

          ],
          "scope": {
            "offset": 1,
            "limit": 10
          }
        },
        "sort": [
          {
            "column": "date",
            "order": "desc"
          }
        ]
      }
    };

    this.loadData();

  }

  ngOnInit(): void {

    this.timer = setInterval(() => { this.checkPausedStatus(); }, 10000);

  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
  checkHolidayStatus() {
    var holidayData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD')
     }
    this.CalendarService.getHolidayStatus(holidayData).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.holiday_status = resp.data.holiday_status;

          if (resp.data.holiday_list.length > 0) {

           // this.getFromDate = moment(resp.data.pauseorder_list[0].from_time).format('HH:mm');
            // this.pausedTime = moment(resp.data.pauseorder_list[0].end_time).format('D-MMM  YYYY, h:mm a');
           // this.pausedTime = this.getPausedTimeDisplay(resp.data.pauseorder_list[0].end_time);
          }

        }
      },
      (error: any) => {
        this.spinner.hide();

      }

    );

  }
  is_holiday(): boolean {
    return this.holiday_status;
  }

  checkPausedStatus() {
    var pauseOrderData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD'),
      time: moment(new Date()).format('HH:mm')


    }
    this.orderApiService.getStatus_PauseOrder(pauseOrderData).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.orderPaused_Status = resp.data.paused_status;

          if (resp.data.pauseorder_list.length > 0) {

            this.getFromDate = moment(resp.data.pauseorder_list[0].from_time).format('HH:mm');
            // this.pausedTime = moment(resp.data.pauseorder_list[0].end_time).format('D-MMM  YYYY, h:mm a');
            this.pausedTime = this.getPausedTimeDisplay(resp.data.pauseorder_list[0].end_time);
          }

        }
      },
      (error: any) => {
        this.spinner.hide();

      }

    );

  }

  /**
   * Format the pause time to display
   * @param endTime 
   * @returns 
   */
  getPausedTimeDisplay(endTime: any): string {

    let pausedTimeDate = ''
    let pausedTime = moment(endTime).format('D-MMM YYYY');
    let today = moment(new Date()).format('D-MMM YYYY');
    let tomorrow = moment().add(1, 'days').format('D-MMM YYYY');

    if (pausedTime === today) {
      pausedTimeDate = 'TODAY';
    } else if (pausedTime === tomorrow) {
      pausedTimeDate = 'TOMORROW';
    } else {
      pausedTimeDate = pausedTime;
    }

    return pausedTimeDate + ' ' + '(' + moment(pausedTime).format('ddd') + ')' + ' ' + moment(endTime).format('h:mm A');
  }

  pausedStatus(): boolean {
    return this.orderPaused_Status;
  }

  /**
  * pauseorder listing.
  * 
  */
  loadData() {
    this.checkPausedStatus();
    this.checkHolidayStatus();
    this.spinner.show();
    this.orderApiService.getPauseOrderList(this.searchData).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.pauseOrderList = resp.data.pauseOrder;
          this.pageData = { cur_page: this.pageData.cur_page, limit: this.pageData.limit, rows: resp.data.num_orders, pages: 20, offset: this.pageData.offset }

          this.spinner.hide();
          this.pageData.pages = Math.ceil(this.pageData.rows / this.pageData.limit);
          this.pageData.pages = (this.pageData.pages < 1) ? 1 : this.pageData.pages;

          if (this.pageData.pages > 1) {
            this.navBtnStatus = true;
          } else {
            this.navBtnStatus = false;
          }
          //this.navPage(0);

        } else {
          this.pauseOrderList = [];
          this.navBtnStatus = false;
          this.spinner.hide();

        }
      },
      (error: any) => {
        this.spinner.hide();

      }

    );
  }

  /**
     * Set the pagination data
     */
  setPageData(): void {
    this.pageData.pages = Math.ceil(this.pageData.rows / this.pageData.limit);
    this.pageData.pages = (this.pageData.pages < 1) ? 1 : this.pageData.pages;

    this.navPage(0);
  }

  /**
    * this method is used to pause ordering for a specific time
    * 
    */
  confirmPauseOrder(): void {
    const dialogRef = this.dialog.open(PauseOrderConfirmComponent, {
      disableClose: true,
      autoFocus: false,
      width: "510px",
      height: "350px",
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined) {
        this.order_paused(result);
      }
    });

  }
  /**
  * resume order
  */
  ResumePauseOrder(): void {
    const dialogRef_resumeOrder = this.dialog.open(PauseOrderResumeComponent, {
      disableClose: true,
      autoFocus: false,
      width: "400px",
      height: "210px",

    });
    dialogRef_resumeOrder.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.Order_resumed();
      }
    });

  }
  /**
   * updated to oo_pause_timing table
   */
  Order_resumed() {
    this.checkPausedStatus();
    var currentTime = formatDate(new Date(), 'HH:mm', 'en-US');

    var timeCal = moment(new Date(), 'HH:mm').subtract(1, 'minutes').toDate();
    var resumedTime = formatDate(timeCal, 'HH:mm', 'en-US');
    var fromTimeval = this.getFromDate;

    const [hours1, minutes1] = fromTimeval.split(':');
    const [hours, minutes] = resumedTime.split(':');
    var totalSecondsResumedtime = (+hours) * 60 * 60 + (+minutes) * 60;
    var totalSecondsFromtime = (+hours1) * 60 * 60 + (+minutes1) * 60;
    var to_time = '';
    if (totalSecondsFromtime <= totalSecondsResumedtime) {
      to_time = resumedTime;
    } else {
      to_time = this.getFromDate;
    }



    var pauseOrderData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD'),
      to_time: to_time,
      user_id: this.userId,

    }
    // console.log(pauseOrderData);
    this.orderApiService.resumeOrder(pauseOrderData).subscribe(
      (response: any) => {

        if (response.status === 0) {
          this.orderPaused_Status = false;
          this.checkPausedStatus();
          this.loadData();
        } else {

        }
      },
      (error: any) => {

      }
    );
  }

  /**
   * save to oo_pause_timing table
   */
  order_paused(minutes:any) {

    var minutesToAdd = minutes;
    var currentDate = new Date();
    var currentTime = formatDate(currentDate, 'HH:mm', 'en-US');
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    var time_to = moment(futureDate).format('HH:mm');
    var starDate = moment(currentDate).startOf('day');
    var endDate = moment(futureDate).startOf('day');
    var is_sameDay = starDate.isSame(endDate);
    if (is_sameDay == false) {
      time_to = moment(currentDate).format('23:59');
    }
    this.from_time = currentTime;

    this.to_time = time_to;
    var pauseOrderData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD'),
      from_time: currentTime,
      to_time: time_to,
      user_id: this.userId,

    }

    this.orderApiService.PauseOrder(pauseOrderData).subscribe(
      (response: any) => {
        console.log(response);
        if (response.status === 0) {
          this.loadData();
        } else {

        }
      },
      (error: any) => {

      }
    );

  }
  /**
   * search based on paused date
   */
  onSearchClick() {

    const starDate = moment(this.timeFrom).format('YYYY-MM-DD');
    const endDate = moment(this.timeTo).format('YYYY-MM-DD');


    if (endDate < starDate) {
      this.notifyService.showError("To date should be greater than from date", "");
      this.hasError = true;

    } else {
      this.hasError = false;
    }
    if (endDate === "Invalid date" || starDate === "Invalid date") {
      this.notifyService.showError("Please select the date range", "");
      this.hasError = true;
    }
    this.datecase = '"' + moment(this.timeFrom).format('YYYY-MM-DD') + '"  and "' + moment(this.timeTo).format('YYYY-MM-DD') + '"';
    this.searchData.search.filters.filter[0].value = this.datecase;
    this.pageData.offset = 1;
    this.searchData.search.filters.scope.offset = 1;
    if (this.hasError === false) {
      this.loadData();
    }

  }

  /**
   * Navigate to previous/next page
   */
  navPage(page: number): void {
    var newPage = this.pageData.cur_page + page;
    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > this.pageData.pages) {
      newPage = this.pageData.pages;
    }
    this.pageData.cur_page = newPage;
    this.searchData.search.filters.scope.offset = (this.searchData.search.filters.scope.limit * (this.pageData.cur_page - 1)) + 1;
    this.pageData.limit = this.searchData.search.filters.scope.limit;
    this.pageData.offset = this.searchData.search.filters.scope.offset;
    this.loadData();

  }

  /**
  * Method is use to export orders (excel/csv).
  * 
  */
  onActionExport(): void {
    var exportParam = {
      shop_id: this.shop_id,
      timeFrom: moment(this.timeFrom).format('YYYY-MM-DD'),
      timeTo: moment(this.timeTo).format('YYYY-MM-DD'),
    }
    this.spinner.show();
    if (this.pauseOrderList.length > 0) {
      this.orderApiService.exportPauseorder(exportParam).subscribe(
        (response: any) => {

          this.spinner.hide();
          if (response.body.type == "application/vnd.ms-excel") {
            this.downLoadFile(response);
          }

        },
      );
    } else {
      this.spinner.hide();
      this.notifyService.showError("No data to export", "");
    }


  }

  /**
   * Method is use to download file.
   * @param response - Array Buffer data
 */
  downLoadFile(response: any): any {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response.body);
    //let dateFormat = require('dateformat');
    let now = new Date();
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

    downloadLink.setAttribute('download', "pauseorder-list-report.xlsx");


    document.body.appendChild(downloadLink);
    downloadLink.click();

  }




}
