import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pause-order-resume',
  templateUrl: './pause-order-resume.component.html',
  styleUrls: ['./pause-order-resume.component.scss']
})
export class PauseOrderResumeComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<PauseOrderResumeComponent>) { }

  ngOnInit(): void {
  }

  actionCancel() { 
  this.dialogRef.close();
}

}
