import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }
  private timerSubject: Subject<void> = new Subject<void>();
  public timer: Observable<void> = this.timerSubject.asObservable();
  private timerId: any = 0;


  /**
   * 
   */
  startTimer(): void {
    if (!this.timerId) {
      this.timerId = setInterval(
        () => {
          this.timerSubject.next()
        }, 1000);
    }
  }

  /**
   * 
   */
  stopTimer(): void {
    clearInterval(this.timerId);
    this.timerId = 0;
  }

}
