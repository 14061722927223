<div class="portal-dashboard-page-wrapper">
  <!-- <div fxLayout="column" fxLayout.lg="row" fxLayoutGap="16px"> -->
    <div class="portal-widget" fxFlex="1 0 auto" fxFlex.lg="100">

      <div class="navBlock">

        <button mat-stroked-button style="float: left;  background: #F44336;
                    color: #fff !important;" (click)="previousMonth()">
          <span class="fa fa-chevron-left"></span>
          
        </button>
        <span class="month-title"> {{activeMonth | monthsCustom }} - {{activeYear}} </span>

        <button mat-stroked-button style="float: right;  background: #F44336;
                        color: #fff !important;" (click)="nextMonth()">
          <span class="fa fa-chevron-right"></span>
        </button>
      </div>


      <div class="clendar-block">

        <div class="calendar-list" style="position: relative; float: left;">
          <div class="cal_list_in_div" *ngIf="loadingCalendar">
            <div class="loading_img"><img src="assets/loading2.gif"></div>
          </div>
          <div class="day-title-container">
          <div class="day prepend calendar-heading" *ngFor="let item of daysLabel let i = index">
            <div class="days_label"> {{ item }} </div>
          </div>
        </div>
        <div class="day-container">
          <div class="day prepend" *ngFor="let item of dummyDaysPrepend let i = index">

          </div>
          <div
            class="day daymainblock {{item.festiveType==1 && item.isValid === 1 ? 'holiday': ''}} {{item.festiveType===2 && item.isValid === 1 ? 'festive-day': ''}} "
            *ngFor="let item of monthArrays let i = index" (click)="actionEditPopup(item)">

            <div class="top-row">
              <div *ngIf="item.holidayType===2 && item.isValid === 1" class="holiday-home-icon"><i class="fa fa-home "
                  style="font-size: 20px; padding-right: 2px;"></i>
              </div>
              <span class="holiday-cal-day-title">
                {{item.description }} </span>

              <div *ngIf="(item.holidayType=='2')" class="holiday-delete-icon-container">
                <i class="fa fa-trash-o holiday-delete-icon"
                  (click)="deleteHoliday(item);$event.stopPropagation();"></i>
              </div>
            </div>

            <div class="middle-row daynumber" > <span class="day-num-block" >{{i+1}}</span>
              <div *ngIf="item.holidayType===1 && item.isValid === 1 " class="national"> </div>
              <div *ngIf="item.holidayType===2 && item.isValid === 1" class="regional"> </div>
            </div>

            <div class="bottom-row">
              <div *ngIf="item.festiveType===2 && item.isValid === 1" class="festive-time">
                <span> {{item.displayTime}}</span>
              </div>
            </div>
          </div>
        </div>
          <!-- 
        </div> -->
        </div>
      </div>
    </div>
  <!-- </div> -->
</div>