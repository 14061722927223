import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { StorageService } from '../../common/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OrderApiService {

  public bodyParams: any;
  public perPage: number = 10;
  public pageCount: number = 1;
  public totalCount: number = 0;
  public offsetCount: number = 0;

  constructor(public http: HttpClient,
    private storage: StorageService,
  ) {


  }

  /**
  *Get order list 
  * @returns 
  */
  getOrderList(bodyParams:any): any {
    return this.http.post<any>(
      environment.dataApiBaseUrl + "/order/get-order-list", JSON.stringify(bodyParams));
  }
  /**
  *order export
  * @param bodyParams 
  */
  exportOrderHistory(bodyParams: any): any {
    return this.http.post(
      environment.dataApiBaseUrl + "/order/get-order-export",
      JSON.stringify(bodyParams), { observe: "response", responseType: 'blob' });  //arraybuffer
  }
  /**
  *Get order details 
  *  @param orderId 
  */
  getOrderDetails(orderId: any): any {
    return this.http.get<any>(
      environment.dataApiBaseUrl + "/order/get-order-details/" + orderId
    );
  }


  /**
  *list pause orders 
  * @param bodyParams
  */

  getPauseOrderList(bodyParams:any): any {
    return this.http.post<any>(
      environment.dataApiBaseUrl + "/order/get-pauseorder-list", JSON.stringify(bodyParams));
  }


  /**
  * pause order
  * 
  */
  PauseOrder(pauseOrderData: any) {
    return this.http.post<any>(environment.dataApiBaseUrl + '/order/pause-order', JSON.stringify(pauseOrderData))
      .pipe(map(response => {
        return response;
      }));
  }

  /**
  *resume pause order
  * @param pauseOrderData 
  */
  resumeOrder(pauseOrderData: any) {
    return this.http.post<any>(environment.dataApiBaseUrl + '/order/resume-order', JSON.stringify(pauseOrderData))
      .pipe(map(response => {
        return response;
      }));
  }

  /**
  *get pause orders status
  * 
  */
  getStatus_PauseOrder(pauseOrderData: any) {
    return this.http.post<any>(environment.dataApiBaseUrl + '/order/pause-order-status', JSON.stringify(pauseOrderData))
      .pipe(map(response => {
        return response;
      }));
  }

  

  /**
  *export pause orders list
  * @param exportParam - search criteria
  */

  exportPauseorder(exportParam: any): any {
    return this.http.post(
      environment.dataApiBaseUrl + "/order/pause-order-export",
      JSON.stringify(exportParam), { observe: "response", responseType: 'blob' });  //arraybuffer
  }
  getOrdersCount(bodyParams: any): any {
    return this.http.post<any>(
      environment.dataApiBaseUrl + "/order/dashboardOrderList", JSON.stringify(bodyParams));
  }

}








