import { Component, ViewEncapsulation, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

/**
 * Data structure for scop
 */
 export interface ScopData{
  offset:number,
  limit:number
}

export interface PagingData{
  cur_page:number,
  rows:number,
  pages?:any,
  scop:ScopData,
};


@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PaginatorComponent implements OnInit, OnChanges {
 

  @Input() pageData!:PagingData;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  constructor() { 

    // this.pageData={ cur_page:2,rows:2,pages:20,scop:{offset:0,limit:10}};

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.setPageData();
  }

  ngOnInit(): void {}


  /**
   * Set the pagination data
   */
   setPageData():void{
    this.pageData.pages= Math.ceil(this.pageData.rows/ this.pageData.scop.limit);
    this.pageData.pages=(this.pageData.pages<1)?1:this.pageData.pages;

    this.navPage(0);
  }

  onLimitChange():void{
    this.setPageData();
    this.notifyChanges();
   

  }

  notifyChanges():void{
    var scop:ScopData={limit:this.pageData.scop.limit,offset:(this.pageData.scop.limit*(this.pageData.cur_page-1))+1}
    this.pageData.scop.offset=scop.offset;
    this.changed.emit(scop);
  }

  /**
   * Navigate to previous/next page
   */
  navPage(page:number):void{
    var newPage=this.pageData.cur_page+page;
    if(newPage<1){
      newPage=1;
    }else if(newPage>this.pageData.pages){
      newPage=this.pageData.pages;
    }
    this.pageData.cur_page=newPage;
    if(page!=0)
      this.notifyChanges();
  }

}
