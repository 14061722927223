<div class="user-list">
 
  <!-- <div class="list-contianer" > -->
     <div class="search-page-bar card cat-filter">
    <div class="main-search-bar">
      <div class="category"> <mat-form-field style="min-width: 300px;">
        <mat-label>Category</mat-label>
        <mat-select class="categorylist"  [formControl]="categoryCtrl"  (selectionChange)='itmCategoryChange($event)' #categorySelect
        panelClass="categorylist-dropdown">
          <mat-option>
            <ngx-mat-select-search [formControl]="categoryFilterCtrl" placeholderLabel="Search.."
            noEntriesFoundLabel="'No category found'" 
             ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let itemcat of filteredCategory | async" [value]="itemcat">
            {{ itemcat.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="saleitemList" >
        <mat-form-field  style="width: 500px;">
          <mat-select [formControl]="saleitemCtrl"   placeholder="Item" (selectionChange)="onChange($event)" #singleSelect 
          panelClass="categorylist-item-dropdown">
            <mat-option>
              <ngx-mat-select-search [formControl]="saleitemFilterCtrl" placeholderLabel="Search.." 
              noEntriesFoundLabel="'No item found'" >
            </ngx-mat-select-search>
            </mat-option>
      
            <mat-option *ngFor="let saleitems of filteredsaleitems | async" [value]="saleitems">
              {{saleitems.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
         
        
    
      
      </div>
      <div class="addbtn">  
        <button mat-flat-button color="primary" [disabled]="disabledStatus" (click)="addOutOfStock()" >ADD</button>
       </div>
    
    </div>
    
  
  </div>
    <div class="table-list-contianer"  style="width: 97%;
   
    margin: auto;
   ">
     
     
  <div class="table-list-header-container table-list-row">
      <div class="table-list-col header sortable col-item" >
          <div class="col-content" >ITEM</div>
          
      </div>
      
      <div class="table-list-col header sortable col-category" >
          <div class="col-content" >CATEGORY</div>
         
      </div>
      <!-- <div class="table-list-col header sortable col-datefrom" >
          <div class="col-content" >FROM DATE</div>
        
      </div>
      <div class="table-list-col header sortable col-dateto" >
          <div class="col-content" >TO DATE</div>
         
      </div> -->
      <div class="table-list-col header col-delete">
          <div class="col-content" style="text-align: center;" >DELETE</div>
          
      </div>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" class="spinner" size="medium" color="#1010ee"  type="ball-clip-rotate-pulse">
  </ngx-spinner>
  <div class="table-list-row-container stock-row-container">
    
   

    <ng-container *ngIf="outOfStock.length>0;then itemList else noData"></ng-container>
      <ng-template #itemList> 
        
        <form [formGroup]="formGroup"   novalidate>
        <ng-container *ngFor="let out_of_stock of outOfStock;  let i = index;">
      <div  class="table-list-row data-row" [ngStyle]="{'background-color':out_of_stock.new_item === '0' && i==0  ? '#d5d4e8' : '' }">
       <!-- <ng-container  [class]="out_of_stock.is_deleted == 1 ? 'strikethrough' : ''"></ng-container> -->
          <div class="table-list-col data col-item">
              <div class="col-content"  [class]="out_of_stock.is_deleted == 1 ? 'strikethrough' : ''">{{out_of_stock.sale_item_name}}</div>
          </div>
          <div class="table-list-col data col-category">
              <div class="col-content"  [class]="out_of_stock.is_deleted == 1 ? 'strikethrough' : ''">{{out_of_stock.category_name}}</div>
          </div>
          <!-- <div class="table-list-col data col-datefrom">
              <div class="col-content" > <mat-form-field  style="width: 106px;" [ngStyle]="{'color':hasError === true && errorId==out_of_stock.sale_item_id ? 'red' : '' }" >
                <input matInput [matDatepicker]="picker_start" [(ngModel)]="out_of_stock.from_date" formControlName="dateFrom"   >
                <mat-datepicker-toggle [ngStyle]="{'color':hasError === true && errorId==out_of_stock.sale_item_id ? 'red' : '' }" matSuffix [for]="picker_start"></mat-datepicker-toggle>
                <mat-datepicker #picker_start></mat-datepicker>
              </mat-form-field></div> 
          </div>
          <div class="table-list-col data col-dateto">
            <div class="col-content" > <mat-form-field  style="width: 106px;" [ngStyle]="{'color':hasError === true && errorId==out_of_stock.sale_item_id ? 'red' : '' }" >
              <input matInput [matDatepicker]="picker_end_i"   [(ngModel)]="outOfStock[i].to_date"  formControlName="dateTo" (dateChange)="addEvent('change', $event)" >
              <mat-datepicker-toggle [ngStyle]="{'color':hasError === true && errorId==out_of_stock.sale_item_id ? 'red' : '' }" matSuffix [for]="picker_end_i"></mat-datepicker-toggle>
              <mat-datepicker #picker_end_i></mat-datepicker>
            </mat-form-field></div> 
          </div> -->
          <div class="table-list-col data col-delete">
              <div class="col-content" style="text-align: center;color:#d01c1c" (click)="deleteItem(i)"><i class="material-icons">delete</i>
              </div>
          </div>
      </div>
    
      </ng-container></form></ng-template>
      <ng-template #noData> <div  class="nodata"> No out of stock items.</div></ng-template>
    
  </div>
</div>


  <!-- </div> -->
  </div>
