import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderApiService } from '../order-api.service';
import { NgxSpinnerService } from 'ngx-spinner';


import { Location } from '@angular/common';
import { OnActionBack } from '../../../side-nav/side-nav.component';
import { StorageService } from '../../../common/services/storage.service';
import { SideNavService } from '../../../side-nav/side-nav.service';
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit, OnActionBack {
  addressSource!: { id: number; paymentAddress: { address: string; city: string; state: string; country: string; zip: string; phone: string; email: string; }; shippingAddress: { address: string; city: string; state: string; country: string; zip: string; phone: string; email: string; }; }[];
  detailProvider: any;
  orderList!: any[];
  orderId: any;
  constructor(private storage: StorageService, private _location: Location, private spinner: NgxSpinnerService, private router: Router, private route: ActivatedRoute, private orderApiService: OrderApiService, private sideNavService: SideNavService) {
    sideNavService.set(this, "Order Details",true);
    this.orderId = this.route.snapshot.paramMap.get('id');
    this.spinner.show();
    this.orderApiService.getOrderDetails(this.orderId).subscribe(
      (response: any) => {
        if (response.status === 0) {
          this.detailProvider = response.data;
          this.spinner.hide();
        }
      },
      (error: any) => { }
    );

  }

  ngOnInit(): void {

  }
  /**
   * back to order listing page.
   * 
 */
  onActionBack(): void {
    this.storage.saveToSession('previousState', 'true');
    this.router.navigate(['/order'], { queryParams: { orderid: this.orderId, previousState: true } });
  }
  getCustomizations(item:any): any {
    return JSON.parse(item.customizations);
  }
  getOrderRemarks(order:any) {
    var remarks = "";
    if (order.orderhdrData.remarks !== null && order.orderhdrData.remarks !== undefined && order.orderhdrData.remarks.length > 0) {
      var rex = /\r\n|\n|\r/gm;
      remarks = order.orderhdrData.remarks.replace(rex, '<br>');
    }
    return remarks;
  }

}
