import {
  Component, OnInit, ViewEncapsulation, Inject,
  ViewChild,
  ElementRef
} from '@angular/core';



import { OrderApiService } from '../order-api.service';
import { ColFilter, SearchData } from '../components/order-search/order-search.component';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrderExportComponent } from '../order-export/order-export.component';
import { ActivatedRoute, Router } from '@angular/router';

import { PagingData,ScopData} from '../../../common/components/paginator/paginator.component';
import { OnActionExport } from '../../../side-nav/side-nav.component';
import { SideNavService } from '../../../side-nav/side-nav.service';
import { NotificationService } from '../../../common/services/notification.service';
import { StorageService } from '../../../common/services/storage.service';
import { SnackBarService } from '../../../common/services/snack-bar.service';




/**
 * Data structure for Sort data
 */
export interface SortData {
  column: String,
  order: String
};

/**
 * Data structure for request
 */
export interface RequestData {
  export: string,
  shop_code: any,
  search?: {
    filters?: {
      filter?: ColFilter[],
      scope?: ScopData,
    }
    sort?: SortData[]
  }
};

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})

export class OrderListComponent implements OnInit, OnActionExport {

  orderList!: any[];
  export: any;
  mode: any;
  sort: SortData = { column: 'order_time', order: 'desc' };
  filters: ColFilter[] = [
    {
      column: "date_type",
      column_label: "Date Type",
      operator: "",
      value: "order_date",
      display_value: "Order Date"
    },
    {
      column: "search_for",
      column_label: "Date Range",
      operator: "",
      value: "today",
      display_value: "Today"
    },
    {
      column: "status",
      column_label: "Status",
      operator: "",
      value: "",
      display_value: ""
    },
    {
      column: "customer_name",
      column_label: "Customer",
      operator: "",
      value: "",
      display_value: ""
    },
    {
      column: "total_amount",
      column_label: "Amount",
      operator: "",
      value: "",
      display_value: ""
    },
    {
      column: "start_date",
      column_label: "Start Date",
      operator: "",
      value: "",
      display_value: ""
    },
    {
      column: "end_date",
      column_label: "End Date",
      operator: "",
      value: "",
      display_value: ""
    }
  ];
  scop: ScopData = { limit: 10, offset: 1 };
  pageData: PagingData = { cur_page: 1, rows: 10, scop: this.scop };
  shop_code: any;
  totalAmount: any;
  numOfOrders: any;
  selectedExportval: any;
  previousStateStatus: any;
  buildRqt: any;
  toggleTotalAmunt!:true;
  searchData :SearchData = {
    mode: "advanced",
    filter: this.filters,
  };
  SelectedOrderId: any;

  constructor(private notifyService: NotificationService, private sideNavService: SideNavService, private orderApiService: OrderApiService, private spinner: NgxSpinnerService,
    private storage: StorageService, private snackBarService: SnackBarService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
    sideNavService.set(this, "Orders",true);
    this.SelectedOrderId = '';
    this.previousStateStatus = this.route.snapshot.queryParams['previousState'];
    this.SelectedOrderId = this.route.snapshot.queryParams['orderid'];


    const user = JSON.parse(this.storage.loadFromSession('user'));
    this.shop_code = user.shop_code;

    if (this.previousStateStatus === undefined || this.previousStateStatus === 'false') {
      this.storage.saveToSession('order-list-page-date', '');
      this.storage.saveToSession('order-list-page-request', '');
    }

    this.OrderList();

  }

  ngOnInit(): void {

    this.toggleAmount(true);

  }

  toggleAmount(toggle:any){
    this.toggleTotalAmunt=toggle;
  }
  /**
   * 
   * @param e Sorting option selected
   */
  colOptionSelected(e: any): void {
    this.sort.column = e.column;
    this.sort.order = e.order;
    this.sort = { column: this.sort.column, order: this.sort.order };
    this.OrderList();
  }

  /**
    * order listing.
    * 
    */
  OrderList() {

    this.spinner.show();
    this.buildRqt = this.buildRequest();

    this.orderApiService.getOrderList(this.buildRqt).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.orderList = resp.data.orders;
          this.pageData = { cur_page: this.pageData.cur_page, rows: resp.data.num_orders, scop: this.scop };
          this.spinner.hide();
          this.numOfOrders = resp.data.num_orders;
          this.totalAmount = resp.data.total_amount;
        } else {
          this.orderList = [];
          this.pageData = { cur_page: this.pageData.cur_page, rows: resp.data.num_orders, scop: this.scop };
          this.totalAmount = 0;
          this.numOfOrders = resp.data.num_orders;
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.notifyService.showError("Failed to list orders.", "");
      }

    );
  }
  /**
   * 
   * @param pageData Pagination has been changed
   */
  onPagination(scop: ScopData): void {

    this.scop = scop;
    this.buildRequest();
    this.OrderList();
  }

  onSearch(event: any): void {
    this.scop.offset = 1;
    this.pageData.scop.offset = 1;
    this.filters = event.filter;
    this.storage.saveToSession('previousState', 'false');
    this.storage.saveToSession('selectedRequest', '');
    this.SelectedOrderId = '';
    this.buildRequest();
    this.OrderList();
  }

  /**
  * Build the request by combining the options
  * @returns 
  */
  buildRequest(): any {

    var requestData = undefined;

    if (this.previousStateStatus === 'true') {
      this.searchData = JSON.parse(this.storage.loadFromSession('order-list-search-data'));
      requestData = JSON.parse(this.storage.loadFromSession('order-list-page-request'));
      this.filters = requestData.search.filters.filter;
      this.sort = requestData.search.sort[0];
      this.pageData = this.storage.loadFromSession('order-list-page-data');
      this.scop = this.pageData.scop;
      this.previousStateStatus = "false";
      this.storage.saveToSession('order-list-page-request', '');
      this.storage.saveToSession('order-list-page-date', '');


    }

    requestData = {
      export: this.selectedExportval,
      shop_code: this.shop_code,
      search: {
        filters: {
          filter: (this.filters == undefined ? [] : this.filters),
          scope: this.scop
        },
        sort: [this.sort]

      }
    }

    return requestData;
  }


  /**
    * Method is use to export orders (excel/csv).
    * 
    */
  onActionExport(): void {
    const dialogRef = this.dialog.open(OrderExportComponent, {
      disableClose: true,
      autoFocus: false,
      width: "22%",
      height: "250px",
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.exportData(result);
      }
    });
  }

  /*
export data based on condition selected 
from the radio button
*/
  exportData(selectedValue: any): void {
    this.selectedExportval = selectedValue;
    this.buildRequest();
    this.spinner.show();
    if (this.orderList.length > 0) {
      this.orderApiService.exportOrderHistory(this.buildRequest()).subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.body.type == "application/vnd.ms-excel") {
            this.downLoadFile(response);
          }

        },
      );
    } else {
      this.spinner.hide();
      this.notifyService.showError("No data to export", "");
    }
  }


  /**
    * Method is use to download file.
    * @param response - Array Buffer data
  */
  downLoadFile(response: any): any {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response.body);
    //let dateFormat = require('dateformat');
    let now = new Date();
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    if (this.selectedExportval == 'all') {
      downloadLink.setAttribute('download', "order-summary-report.xlsx");
    } else {
      downloadLink.setAttribute('download', "item-list-report.xlsx");
    }

    document.body.appendChild(downloadLink);
    downloadLink.click();

  }
  /**
    *  order detail view.
    * @param id - orderId
  */
  detailView(id:any) {
    var selected_page = this.buildRequest();
    this.storage.saveToSession("order-list-search-data", JSON.stringify(this.searchData));
    this.storage.saveToSession('order-list-page-data', this.pageData);
    this.storage.saveToSession('order-list-page-request', JSON.stringify(selected_page));
    this.router.navigate(['/order-details', id]);
  }


}
