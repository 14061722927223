import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pause-order-confirm',
  templateUrl: './pause-order-confirm.component.html',
  styleUrls: ['./pause-order-confirm.component.scss']
})
export class PauseOrderConfirmComponent implements OnInit {
  time = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "110", "120"];
  selectedValue: any = this.time[0];
  orderId: any;
  status: any;
  captcha: string[] = [
    Math.floor(Math.random() * (9 - 0 + 1) + 0).toString(),
    Math.floor(Math.random() * (9 - 0 + 1) + 0).toString(),
    Math.floor(Math.random() * (9 - 0 + 1) + 0).toString(),
    Math.floor(Math.random() * (9 - 0 + 1) + 0).toString()
  ];
  captchaInput: string[] = ['', '', '', ''];

  @ViewChild('ci1') ci1!: ElementRef<HTMLInputElement>;
  @ViewChild('ci2') ci2!: ElementRef<HTMLInputElement>;
  @ViewChild('ci3') ci3!: ElementRef<HTMLInputElement>;
  @ViewChild('ci4') ci4!: ElementRef<HTMLInputElement>;
  constructor(private dialogRef: MatDialogRef<PauseOrderConfirmComponent>) {

  }

  ngOnInit(): void {
    setTimeout(() => {
        this.setFocus(this.ci1);
    },150);
  }
  actionCancel() {
    this.dialogRef.close();
  }

  /**
   * On captcha entered
   * @param id 
   */
  onCaptcha(id: any): void {

    setTimeout(() => {
      if (id == 'ci1')
        this.setFocus(this.ci2);
      if (id == 'ci2')
      this.setFocus(this.ci3);
      if (id == 'ci3')
      this.setFocus(this.ci4);
    }, 150);
  }
  /**
  * Set the focus to the element
  * @param ele 
  */
  setFocus(ele:any):void {
    ele.nativeElement.focus();
    ele.nativeElement.select();
  }
  /**
   * 
   * @returns Validate the captcha
   */
  isCaptchaValid(): boolean {
    return (JSON.stringify(this.captcha) == JSON.stringify(this.captchaInput));
  }

}
