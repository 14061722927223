<form [formGroup]="stockexportForm" (ngSubmit)="onSubmit()" novalidate>

    <div class="pop-up-export" >
        <div class="contaner">
            <div>
                <h2 class="title-class" mat-dialog-title>Export Out Of Stock</h2>
            </div>
            <div (click)="actionCancel()"><span class="closeIconExport">
                    X
                </span></div>
        </div>
        <hr>

        <div class="main-container">

            <div class="content-container">

                <div style="width:32%;text-align: center;
    vertical-align: middle;
    margin-top: 22px;
"><label>Export type :</label></div>
                <div class="option-container" style="width:50%;">

                    <mat-form-field>

                        <select matNativeControl required class="grp-esport-type" formControlName="exportType"
                            [(ngModel)]="selectedValue" (change)='exportChange(selectedValue)'>
                            <option *ngFor="let option of orderExportDataOption" [value]="option.value">
                                {{ option.label }}
                            </option>
                        </select>
                    </mat-form-field>
                </div>

            </div>
            <!-- .............. -->
            <div style="width:100%; display: flex;
    flex-direction: row;" *ngIf="showDaterange">

                <div style="width:32%;text-align: center;
    vertical-align: middle;
    margin-top: 22px;"><label>Date Range :</label></div>
                <div class="option-container" style="width:50%;">

                    <mat-form-field>
                        <!-- <mat-label>Enter a date range</mat-label> -->
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate formControlName="dateFrom" [(ngModel)]="dateFrom">
                            <input matEndDate formControlName="dateTo" [(ngModel)]="dateTo">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <!-- </mat-radio-group> -->
            </div>
        </div>


        <div class="confirm-btn">
            <button mat-raised-button color="primary" class="button-cancel" type="button"
                (click)="actionCancel()">Cancel</button>
          
            <button mat-raised-button color="primary" class="button-ok">OK</button>
        </div>
        
    </div>
</form>