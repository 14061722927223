<div class="portal-accent-bg portal-login-container" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="none">
  <mat-card class="portal-login-card mat-elevation-z" fxFlex="100" fxFlex.md="80" fxFlex.gt-md="60"
    fxLayout.lt-sm="column">
    <mat-card-content fxFlex="auto" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutAlign="space-between none"
      class="portal-primary-bg">
      <div class="co_logo_container">
        <img class="custom_logo" src="assets/images/logo/logo.png" />
        <mat-card-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <h1 class="co_logo_title" i18n> Online Shop Admin Panel</h1>
        </mat-card-title>
        <div>
        </div>
      </div>
    
    </mat-card-content>
    <mat-card-content fxFlex="auto" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutAlign="space-between none">
     
      <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
        <mat-form-field style="width: 100%;">
          <mat-label>Select Shop</mat-label>
          <mat-select formControlName="shopId">
            <!-- <mat-option>None</mat-option> -->
            <mat-option *ngFor="let shops of ShopList" [value]="shops.shop_id">{{shops.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 100%;">

          <mat-label>User name</mat-label>
          <input matInput formControlName="userName">
        </mat-form-field>
        <!-- <mat-form-field>
            <input matInput formControlName="userName" placeholder="Username" (keyup.enter)="loginUser()">
          </mat-form-field> -->
        <mat-form-field style="width: 100%;">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" placeholder="Password">
        </mat-form-field>



        <div class="alert alert-danger" *ngIf="response.status==401">
          {{ response.message}}
        </div>
        <div fxLayout="column" fxLayoutGap="16px">
          <div fxLayout="row" fxLayoutGap="16px" fxLayout.lt-md="column">
            <button mat-raised-button i18n type="submit" fxFlex="100%" fxFlex.lt-md="none" color="primary"
              (click)="loginUser()" [disabled]="!loginForm.valid">
              Login
            </button>
           
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>