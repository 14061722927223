import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private http: HttpClient) { }

  /* Save Customer details in to session Storage*/
  saveToSession(key: string, data: any): void {
    sessionStorage.setItem(key, data);
  }

   /* Load Customer details from session Storage*/
  loadFromSession(key: string): any {
    return sessionStorage.getItem(key)
  }

  /* Clear Customer details from session Storage when LOGOUT*/
  clearSession() {
    sessionStorage.clear();
  }

   /* Save Customer ID in to local Storage*/
  saveToLocalStorage(customerId: any): any {
    localStorage.setItem('customerId', customerId);
  }

   /* Load CustomerID from session Storage*/
  loadFromLocalStorage(): any {
    return localStorage.getItem('customerId');
  }

   /* Clear Customer ID from local Storage when LOGOUT*/
  clearlocalStorage() {
    localStorage.clear();
  }
}
