import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { OrderApiService } from '../order/order-api.service';
import { CalendarService } from '../calendar/calendar.service';
import { PauseOrderResumeComponent } from '../pause-order/pause-order-resume/pause-order-resume.component';
import { formatDate } from '@angular/common';
import { PauseOrderConfirmComponent } from '../pause-order/pause-order-confirm/pause-order-confirm.component';
import { StockApiService } from '../stock/stock-api.service';
import { NotificationService } from '../../common/services/notification.service';
import { SideNavService } from '../../side-nav/side-nav.service';
import { StorageService } from '../../common/services/storage.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  shop_id: any;
  userId: any;
  orderPaused_Status: boolean;
  holiday_status: boolean;
  getFromDate: any;
  pausedTime: any;
  from_time: any;
  to_time: any;
  outOfStock: any;
  totalStockcount: any;
  shop_code: any;
  todayOrderCnt: any;
  todayTotalAmt: any;
  thisMonthTotalAmt: any;
  thisMonthOrderCnt: any;
  thisWeekTotalAmt: any;
  thisWeekOrderCnt: any;
  todayDeliveredTotalAmt: any;
  todayDeliveredOrderCnt: any;
  toDeliverOrders: any;
  toDeliverTotalAmt: any;
  timer:any;

  constructor(private sideNavService: SideNavService,
    private storage: StorageService,
    private orderApiService: OrderApiService,
    private CalendarService: CalendarService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private stockApiService: StockApiService,
    private notifyService: NotificationService) {
    sideNavService.set(this, "Dashboard",false);
    const user = JSON.parse(this.storage.loadFromSession('user'));
    this.shop_id = user.shop_id;
    this.userId = user.user_id;
    this.shop_code = user.shop_code;
    this.orderPaused_Status = false;
    this.holiday_status=false;
    this.checkPausedStatus();
    this.checkHolidayStatus();
    this.loadOutOfStockItems();
    this.orderCount();
    
  }

  ngOnInit(): void {
    this.timer= setInterval(() => {     
      this.checkPausedStatus();
      this.checkHolidayStatus();
      this.loadOutOfStockItems();
      this.orderCount(); }, 10000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  orderCount() {
    var cdate = moment(new Date()).format('YYYY-MM-DD');
    var param = {
      shop_code: this.shop_code,
      cdate: cdate
    }
    this.spinner.show();
    this.orderApiService.getOrdersCount(param).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.spinner.hide();
          this.todayOrderCnt = resp.data.num_orders_today;
          this.todayTotalAmt = resp.data.total_amount_today;
          this.thisWeekOrderCnt = resp.data.num_orders_thisweek;
          this.thisWeekTotalAmt = resp.data.total_amount_thisweek;
          this.thisMonthOrderCnt = resp.data.num_orders_thismonth;
          this.thisMonthTotalAmt = resp.data.total_amount_thismonth;
          this.todayDeliveredOrderCnt = resp.data.num_orders_delivered;
          this.todayDeliveredTotalAmt = resp.data.total_amount_delivered;
          this.toDeliverOrders = resp.data.toDeliverOrders;
          this.toDeliverTotalAmt = resp.data.toDeliverTotalAmt;


        } else {
          this.spinner.hide();
          this.todayOrderCnt = 0;
          this.todayTotalAmt = 0;
          this.thisWeekOrderCnt = 0;
          this.thisWeekTotalAmt = 0;
          this.thisMonthOrderCnt = 0;
          this.thisMonthTotalAmt = 0;
          this.todayDeliveredOrderCnt = 0;
          this.todayDeliveredTotalAmt = 0;
          this.toDeliverOrders = 0;
          this.toDeliverTotalAmt = 0;
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.notifyService.showError("Failed to get the orders.", "");
      }

    );

  }

  /**
* list all outof stock items
* 
*/
  loadOutOfStockItems() {
    var cdate = moment(new Date()).format('YYYY-MM-DD');
    var param = {
      shopId: this.shop_id,
      cdate: cdate
    }
    this.spinner.show();
    this.stockApiService.getOutOfStockItems(param).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.spinner.hide();
          this.outOfStock = resp.data.outOfStock;
          this.totalStockcount = this.outOfStock.length;

        } else {
          this.spinner.hide();
          this.outOfStock = [];
          this.totalStockcount = 0;
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.notifyService.showError("Failed to get the stock items count.", "");
      }

    );

  }
  // check pause order status
  pausedStatus(): boolean {
    return this.orderPaused_Status;
  }

  checkPausedStatus() {
    var pauseOrderData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD'),
      time: moment(new Date()).format('HH:mm')


    }
    this.orderApiService.getStatus_PauseOrder(pauseOrderData).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.orderPaused_Status = resp.data.paused_status;

          if (resp.data.pauseorder_list.length > 0) {

            this.getFromDate = moment(resp.data.pauseorder_list[0].from_time).format('HH:mm');
            this.pausedTime =this.getPausedTimeDisplay(resp.data.pauseorder_list[0].end_time);
            // moment(resp.data.pauseorder_list[0].end_time).format('D-MMM  YYYY, h:mm A');
          }

        }
      },
      (error: any) => {
        this.spinner.hide();

      }

    );

  }

  is_holiday(): boolean {
    return this.holiday_status;
  }

 /**
* check holiday status
* 
*/
  checkHolidayStatus() {
    var holidayData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD')
     }
    this.CalendarService.getHolidayStatus(holidayData).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.holiday_status = resp.data.holiday_status;

        }
      },
      (error: any) => {
        this.spinner.hide();

      }

    );

  }
  /**
   * Format the pause time to display
   * @param endTime 
   * @returns 
   */
  getPausedTimeDisplay(endTime: any): string {
    
    let pausedTimeDate = ''
    let pausedTime = moment(endTime).format('D-MMM YYYY');
    let today = moment(new Date()).format('D-MMM YYYY');
    let tomorrow = moment().add(1, 'days').format('D-MMM YYYY');

    if (pausedTime === today) {
      pausedTimeDate = 'TODAY';
    } else if (pausedTime === tomorrow) {
      pausedTimeDate = 'TOMORROW';
    } else {
      pausedTimeDate = pausedTime;
    }

    return pausedTimeDate + ' ' + '(' + moment(pausedTime).format('ddd') + ')' + ' ' + moment(endTime).format('h:mm A');
  }
  /**
   * this method is used to pause ordering for a specific time
   * 
   */
  confirmPauseOrder(): void {
    const dialogRef = this.dialog.open(PauseOrderConfirmComponent, {
      disableClose: true,
      autoFocus: false,
      width: "510px",
      height: "350px",
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result !== undefined) {
        this.order_paused(result);
      }
    });

  }

  /**
* save to oo_pause_timing table
*/
  order_paused(minutes:any) {

    var minutesToAdd = minutes;
    var currentDate = new Date();
    var currentTime = formatDate(currentDate, 'HH:mm', 'en-US');
    var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    var time_to = moment(futureDate).format('HH:mm');
    var starDate = moment(currentDate).startOf('day');
    var endDate = moment(futureDate).startOf('day');
    var is_sameDay = starDate.isSame(endDate);
    if (is_sameDay == false) {
      time_to = moment(currentDate).format('23:59');
    }
    this.from_time = currentTime;

    this.to_time = time_to;
    var pauseOrderData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD'),
      from_time: currentTime,
      to_time: time_to,
      user_id: this.userId,

    }

    this.orderApiService.PauseOrder(pauseOrderData).subscribe(
      (response: any) => {
        //console.log(response);
        if (response.status === 0) {
          this.checkPausedStatus();
        } else {

        }
      },
      (error: any) => {

      }
    );
    this.checkPausedStatus();
  }

  /**
 * resume order
 */
  ResumePauseOrder(): void {
    const dialogRef_resumeOrder = this.dialog.open(PauseOrderResumeComponent, {
      disableClose: true,
      autoFocus: false,
      width: "400px",
      height: "210px",
      

    });
    dialogRef_resumeOrder.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.Order_resumed();
      } 
    });

  }
  /**
   * updated to oo_pause_timing table
   */
  Order_resumed() {
    this.checkPausedStatus();
    var currentTime = formatDate(new Date(), 'HH:mm', 'en-US');

    var timeCal = moment(new Date(), 'HH:mm').subtract(1, 'minutes').toDate();
    var resumedTime = formatDate(timeCal, 'HH:mm', 'en-US');
    var fromTimeval = this.getFromDate;

    const [hours1, minutes1] = fromTimeval.split(':');
    const [hours, minutes] = resumedTime.split(':');
    var totalSecondsResumedtime = (+hours) * 60 * 60 + (+minutes) * 60;
    var totalSecondsFromtime = (+hours1) * 60 * 60 + (+minutes1) * 60;
    var to_time = '';
    if (totalSecondsFromtime <= totalSecondsResumedtime) {
      to_time = resumedTime;
    } else {
      to_time = this.getFromDate;
    }



    var pauseOrderData = {
      shop_id: this.shop_id,
      date: moment(new Date()).format('YYYY-MM-DD'),
      to_time: to_time,
      user_id: this.userId,

    }

    this.orderApiService.resumeOrder(pauseOrderData).subscribe(
      (response: any) => {

        if (response.status === 0) {
          this.orderPaused_Status = false;
          this.checkPausedStatus();

        } else {

        }
      },
      (error: any) => {

      }
    );
  }

}




