import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(public http: HttpClient) { }

  getFestiveTimings(bodyParam: any){
    return this.http.post<any>(
      environment.javaApiBaseUrl + "/holiday/get-shop-holiday-list-for-edit",
      bodyParam
    );
  }

  holidaySaveNew(bodyParam: any): any {
    return this.http.post<any>(
      environment.javaApiBaseUrl + "/holiday/save",
      bodyParam
    );
  }

  getShopList(): any {
    return this.http.get<any>(environment.javaApiBaseUrl + "/shop/get-list");
  }

  getRegionList(): any {
    return this.http.get<any>(environment.javaApiBaseUrl + "/region/get-list");
  }

  holidayGetListByDate(bodyParam: any): any {
    return this.http.post<any>(
      environment.javaApiBaseUrl + "/holiday/get-list",
      bodyParam
    );
  }


  deleteHoliday(hdrId: any){
 
    return this.http.get(
      environment.javaApiBaseUrl + "/holiday/delete?id=" + hdrId
    );

}
/**
  *get holiday status
  * 
  */
  getHolidayStatus(holidayData: any) {
    return this.http.post<any>(environment.dataApiBaseUrl + '/order/holiday-status', JSON.stringify(holidayData))
      .pipe(map(response => {
        return response;
      }));
  }

}
