import { Component, Input, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html', 
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }
  public confirmMessage:  string = '';
  public contentMessage: string = '';
  ngOnInit(): void {
  }

}
