<div class="pop-up-export">
  <h2 class="title-class" mat-dialog-title>PAUSE ONLINE SALES</h2>
  <div (click)="actionCancel()"><span class="closeIconExport">
      x
    </span></div>
  <hr>
  <div  style="margin-top: 40px;">Temporarily pause the online sales for next

     <span>
      <select style="display: inline;margin-left:12pxpx;width: 57px;
    height: 33px;text-align: center;" [(ngModel)]="selectedValue">

        <option *ngFor="let timelimit of time" [ngValue]="timelimit">{{timelimit}}</option>
      </select>
      <label style="margin-left: 10px;">Minuts</label></span>
  </div>
  <div class="captcha-container">
    <div class="captcha-preview-container">
      <span class="captcha-input" maxlength="1" readonly>{{captcha[0]}}</span>
      <span class="captcha-input" maxlength="1" readonly>{{captcha[1]}}</span>
      <span class="captcha-input" maxlength="1" readonly>{{captcha[2]}}</span>
      <span class="captcha-input" maxlength="1" readonly>{{captcha[3]}}</span>
      <div class="cpatcha-status-container-dummy captcha-input"></div>
    </div>
    <span class="captcha-info">Please enter above code below</span>
    <div class="captcha-input-container">
      <input type="tel" #ci1 class="captcha-input" maxlength="1" [(ngModel)]=captchaInput[0] (keypress)="onCaptcha('ci1')">
      <input type="tel"  #ci2 class="captcha-input" maxlength="1" [(ngModel)]=captchaInput[1] (keypress)="onCaptcha('ci2')">
      <input type="tel" #ci3 class="captcha-input" maxlength="1" [(ngModel)]=captchaInput[2] (keypress)="onCaptcha('ci3')">
      <input type="tel" #ci4 class="captcha-input" maxlength="1" [(ngModel)]=captchaInput[3] (keypress)="onCaptcha('ci4')">

       <div class="captcha-status-container">
        <mat-icon *ngIf="isCaptchaValid()" class="captcha-status captcha-status-valid " >check_circle</mat-icon>
        <mat-icon *ngIf="!isCaptchaValid()" class="captcha-status captcha-status-invalid" >cancel</mat-icon>
      </div>

    </div>
  </div>
  <div class="confirm-btn"><button mat-raised-button color="primary" class="button-cancel"
      (click)="actionCancel()">CANCEL</button>
    <button mat-raised-button color="primary" class="button-ok" [mat-dialog-close]="selectedValue"
      cdkFocusInitial [disabled]="!isCaptchaValid()">CONFIRM</button>
  </div>

</div>