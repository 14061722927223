
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  /**
   * Displays the error messages
   * @param message 
   */
  error(message: string): void {
    if(message==undefined || message.trim().length==0) return;
    const config = new MatSnackBarConfig();
    config.panelClass = ['snack-bar-error'];
    config.duration = 5000;
    this.snackBar.open(message, "x", config);
  }
  /**
   * Displays the success messages
   * @param message 
   */
  success(message: string): void  {
    if(message==undefined || message.trim().length==0) return;
    const config = new MatSnackBarConfig();
    config.panelClass = ['snack-bar-success'];
    config.duration = 5000;
    this.snackBar.open(message,  "x", config);
  }
  /**
   * Displays the warning messages
   * @param message 
   */
  warning(message: string): void  {
    if(message==undefined || message.trim().length==0) return;
    const config = new MatSnackBarConfig();
    config.panelClass = ['snack-bar-warning'];
    config.duration = 5000;
    this.snackBar.open(message, "x", config);
  }
}
