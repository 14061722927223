/**
 * Session storage keys
 */
export const SESSION_MASTER_DATA = "master-data";
export const SESSION_TERMINAL_MASTER_DATA = "terminal-data";
export const SESSION_TERMINAL_CONFIG = "terminal-configuration";
export const SESSION_SERVICE_TOCKEN = "service-token";

/**
 * CDS specific constants
 */
 export const SESSION_TERMINAL_STARTUP_MODE= "startup_display_mode";
/**
 * Order status
 */
export const ORDER_SATUS_NONE = 0;
export const ORDER_SATUS_QUEUED = 1;
export const ORDER_SATUS_STARTED = 10;
export const ORDER_SATUS_FINISHED = 20;
export const ORDER_SATUS_PARKED = 90;
export const ORDER_SATUS_CACELLED = 95;
export const ORDER_SATUS_DELIVERED = 99;


/**
 * ITEM SATUS
 */
export const ITEM_SATUS_NONE = ORDER_SATUS_NONE;
export const ITEM_SATUS_QUEUED = ORDER_SATUS_QUEUED;
export const ITEM_SATUS_UPDATED = 5;
export const ITEM_SATUS_STARTED = ORDER_SATUS_STARTED;
export const ITEM_SATUS_FINISHED = ORDER_SATUS_FINISHED;

/**
 * ITEM SATUS STYLE
 */
 export const ITEM_SATUS_STYLE_DELETED = "deleted";
 export const ITEM_SATUS_STYLE_QUEUED = "queued";
 export const ITEM_SATUS_STYLE_NEW = "new";
 export const ITEM_SATUS_STYLE_UPDATED = "updated";
 export const ITEM_SATUS_STYLE_FINISHED = "finished";

/**
 * Terminal typed
 */
export const TERMINAL_TYPE_KITCHEN = 'KDS';
export const TERMINAL_TYPE_PRODUCTION = 'PDS';
export const TERMINAL_TYPE_CUSTOMER = 'CDS';
export const TERMINAL_TYPE_POST = 'POST';

/**
 * Kitchen types
 */
export const KITCHEN_TYPE_SUSHI = 'SUSHI';
export const KITCHEN_TYPE_BENTO = 'BENTO';

/**
 * Socket server constants
 */

export const WSS_EVENT_TARGET_TYPE_CDS = TERMINAL_TYPE_CUSTOMER;
export const WSS_EVENT_TARGET_TYPE_KDS = TERMINAL_TYPE_KITCHEN;
export const WSS_EVENT_TARGET_TYPE_PDS = TERMINAL_TYPE_PRODUCTION;
export const WSS_EVENT_TARGET_TYPE_POST =TERMINAL_TYPE_POST;
export const WSS_EVENT_TARGET_TYPE_ANY = "ANY";

export const WSS_EVENT="wss_event";
export const WSS_EVENT_ORDER_KITCHEN_STATUS_UPDATE="wss_event_order_kitchen_status_changed";

export const WSS_EVENT_ORDER_QUEUED="wss_event_order_queued";
export const WSS_EVENT_ORDER_PARKED="wss_event_order_parked";

export const WSS_EVENT_CUSTOMER_DISPLAY_SET_ITEM_SELECTED= "wss_event_customer_display_set_item_selected";
export const WSS_EVENT_CUSTOMER_DISPLAY_UPDATE="wss_event_customer_display_update";
export const WSS_EVENT_CUSTOMER_DISPLAY_SCROLL="wss_event_customer_display_scroll";
export const WSS_EVENT_TERMINAL_INFO="wss_event_terminal_info";
export const WSS_EVENT_TERMINAL_INFO_REQUEST = "wss_event_terminal_info_request";

/**
 * customer display scroll values
 */
 export const WSS_CUSTOMER_DISPLAY_SCROLL_PAGE_UP="wss_customer_display_scroll_up";
 export const WSS_CUSTOMER_DISPLAY_SCROLL_PAGE_DOWN="wss_customer_display_scroll_down";
 export const WSS_CUSTOMER_DISPLAY_SCROLL_TOP = "wss_customer_display_scroll_top";
 export const WSS_CUSTOMER_DISPLAY_SCROLL_BOTTOM = "wss_customer_display_scroll_bottom";

/**
 *  customer display modes constants
 */
 export const CUSTOMER_DISPLAY_MODE_CLOSED="0";
 export const CUSTOMER_DISPLAY_MODE_STARTED="1";
 export const CUSTOMER_DISPLAY_MODE_ORDER_NEW="10";
 export const CUSTOMER_DISPLAY_MODE_ORDER_UPDATED="20";
 export const CUSTOMER_DISPLAY_MODE_ORDER_PARKED="30";
 
/**
 * Padding constants in disply
 */
 export const ORDER_LIST_V_HAP=5;
 export const ORDER_LIST_H_HAP=5;

 /**
  * Printe request types
  */
 export const PRINT_REQUEST_TYPE_KITCHEN_RECIPT="kitchenprint";
 export const PRINT_REQUEST_TYPE_PAYMENT_RECIPT="billprint";