<div class="page-bar">
    <!-- <mat-form-field appearance="legacy"> -->
    <mat-select [(value)]="pageData.scop.limit" (selectionChange)="onLimitChange()">
        <mat-option [value]=5> 5</mat-option>
        <mat-option [value]=10> 10</mat-option>
        <mat-option [value]=25> 25</mat-option>
        <mat-option [value]=50> 50</mat-option>
        <mat-option [value]=100> 100</mat-option>
      </mat-select>
    <!-- </mat-form-field> -->
    <button mat-button (click)="navPage(-1)"><mat-icon>arrow_left</mat-icon></button>
    <input type="number" [(ngModel)]="pageData.cur_page" (keyup.enter)="onLimitChange()"  min="1" max="{{pageData.pages}}">
    <span>/</span>
    <input type="text" disabled [value]="pageData.pages">     
    <button mat-button (click)="navPage(1)"><mat-icon>arrow_right</mat-icon></button>
</div>