import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';

export interface ColFilter {
  column: String,
  operator: String,
  value: String,
}
export interface SearchData {
  mode: String,
  filter: ColFilter[]
};

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserSearchComponent implements OnInit {

  searchText: any;
  isOpen: boolean = false;

  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  searchData: SearchData = {
    mode: "simple",
    filter: [
      {
        column: "code",
        operator: "",
        value: "",
      },
      {
        column: "name",
        operator: "",
        value: "",
      },
      {
        column: "group",
        operator: "",
        value: "",
      },
      {
        column: "card",
        operator: "",
        value: "",
      }
    ],
  };


  constructor() { }

  ngOnInit(): void { }

  /**
   * When advance search button cliked,
   * need to set up the search data
   */
  onAdvanceSearchClick() {
    this.isOpen = !this.isOpen;
    if (this.searchData.mode == "simple") {
      this.searchData.filter?.forEach(ft => {
        ft.value = '';
      });
    }
  }

  /**
   * Reset click.
   * Clear the serch data and set it back to simple search mode
   */
  onResetClick(): void {
    this.searchData.mode = "simple",
      this.searchData.filter?.forEach(ft => {
        ft.value = "";
      });
    this.searchText = "";
  }


  /**
   * When filteres are removed update the searchdata
   * @param ft 
   */
  onFilterRemoved(ft: any) {
    ft.value = '';
    var removedCol = this.searchData.filter?.find(f => f.column == ft.column);
    if (removedCol !== undefined) removedCol.value = '';
    var searchMode = (this.searchData.filter?.find(f => f.value !== '') !== undefined) ? 'advanced' : 'simple';
    this.onSearchClick(searchMode);
  }

  /**
   * On search button click
   */
  onSearchClick(mode: any): void { alert('vvv');

    this.isOpen = false;
    this.searchData.mode = mode;

    var searchJson: SearchData = JSON.parse(JSON.stringify(this.searchData));

    if (mode == "simple") {
      searchJson.filter?.forEach(ft => {
        ft.value = this.searchText;
        ft.operator = 'like'
      });
    }else{
      searchJson.filter?.forEach(ft => {
        ft.operator = '='
      });
    }

    this.onSearch.emit(searchJson);
  }

}
