import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { StorageService } from '../../common/services/storage.service';
import { AuthService } from '../../common/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class StockApiService {

  constructor(public http: HttpClient,
    private storage: StorageService,
    private auth: AuthService) { }

  /**
 *Get item category list 
 * @returns 
 */
  getItemCategory() {
    return this.http.get(environment.dataApiBaseUrl + '/stock/get-itemcategory-list');
  }
  /**
 * list all sale items based on selected categoryid
 * 
 */
  getSaleitems(searchdata: any) {
    return this.http.post(environment.dataApiBaseUrl + '/stock/get-saleitem-list', JSON.stringify(searchdata));
  }
  /**
 * list all out of stock items
 * 
 */
  getOutOfStockItems(param: any) {
    return this.http.post(environment.dataApiBaseUrl + '/stock/get-outof-stock-list', JSON.stringify(param));
  }
  /**
  * save out of stock items
  * 
  */
  saveOutOfStock(stockData: any) {
    return this.http.post(environment.dataApiBaseUrl + '/stock/save-outof-stock', JSON.stringify(stockData));
  }
  /**
  * export out of stock items
  * 
  */
  exportOutofStock(exportParam: any): any {
    return this.http.post(
      environment.dataApiBaseUrl + "/stock/outof-stock-export",
      JSON.stringify(exportParam), { observe: "response", responseType: 'blob' });  //arraybuffer
  }

}
