import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

 import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { StockApiService } from '../stock-api.service';
import { StorageService } from '../../../common/services/storage.service';
import { NotificationService } from '../../../common/services/notification.service';

@Component({
  selector: 'app-outofstock-export',
  templateUrl: './outofstock-export.component.html',
  styleUrls: ['./outofstock-export.component.scss']
})
export class OutofstockExportComponent implements OnInit {
  orderExportDataOption: any[] = [{ label: 'All', value: 'all' }, { label: 'Current', value: 'current' }];
  selectedValue: any = this.orderExportDataOption[1].value;
  dateTo: Date;
  dateFrom: Date;
  showDaterange: boolean;
  stockexportForm: FormGroup;
  shopId: any;


  constructor(private notifyService: NotificationService, private storage: StorageService, private stockApiService: StockApiService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<OutofstockExportComponent>) {
    this.stockexportForm = this.formBuilder.group({
      exportType: ['', [Validators.required,]],
      dateFrom: ['', [Validators.required]],
      dateTo: ['', [Validators.required]]

    })
    this.dateTo = new Date();
    var date = new Date();
    this.dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);
    this.showDaterange = false;
    const user = JSON.parse(this.storage.loadFromSession('user'));
    this.shopId = user.shop_id;

  }

  ngOnInit(): void {
    

  }
  get getControl() {
    return this.stockexportForm.controls;
  }
  actionCancel() {
    this.dialogRef.close();
  }
  /**
  * Method is use to select export type.
  * 
  */
  exportChange(selectedValue:any) {
    if (selectedValue == 'all') {
      this.showDaterange = true;
    } else {
      this.showDaterange = false;
    }
  }


  /**
  * Method is use to export out of stock items.
  * 
  */

  onSubmit() {
    if (this.selectedValue == 'all') {
       exportParam = {
        shop_id: this.shopId,
        dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
        dateTo: moment(this.dateTo).format('YYYY-MM-DD')

      }
    } else if (this.selectedValue == 'current') {
      var exportParam = {
        shop_id: this.shopId,
        dateFrom: moment(new Date()).format('YYYY-MM-DD'),
        dateTo: moment(new Date()).format('YYYY-MM-DD')

      }
    }
    this.spinner.show();
    this.stockApiService.exportOutofStock(exportParam!).subscribe(
      (response: any) => {

        this.spinner.hide();
        if (response.body.type == "application/vnd.ms-excel") {
          this.downLoadFile(response);
        } else {
          this.dialogRef.close();
          this.notifyService.showError("No data to export", "");
        }

      },
    );

  }

  /**
  * Method is use to download file.
  * @param response - Array Buffer data
  */
  downLoadFile(response: any): any {
    let dataType = response.type;
    let binaryData = [];
    binaryData.push(response.body);
    let now = new Date();
    var cdate = '"' + moment(now).format('DDMMYYYY');
    var filename = "outofstock-list-report" + cdate + ".xlsx";
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.dialogRef.close();

  }



}
