<div class="pop-up-export">
    <h2 class="title-class" >RESUME ONLINE SALES</h2>
    <div (click)="actionCancel()"><span class="closeIconExport">
      x
    </span></div>
    
    <hr>
    <div  style="margin-top: 40px;text-align: left;">Are you sure you want to resume online sales?

    </div>
    <div class="confirm-btn">
    <button mat-raised-button color="primary" class="button-cancel" (click)="actionCancel()">NO</button>
    <button mat-raised-button color="primary" class="button-ok" [mat-dialog-close]="true" 
      cdkFocusInitial>YES</button>
    </div>
  </div>