<div class="search-bar">

    <!-- <form [formGroup]="orderSearchForm"> -->
    <div class="search-input" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <div *ngIf="searchData.mode=='simple'" class="free-search-input">
            <input matInput type="text" placeholder='Search...' [(ngModel)]="searchText"
                (keyup.enter)="onSearchClick('simple')">
            <button class="clear" *ngIf="searchText" matSuffix (click)="searchText=''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <!-- <div *ngIf="searchData.mode=='advanced'" class="adv-search-input scrollbar-primary">
            <mat-chip-listbox>

                <ng-container *ngFor="let ft of searchData.filter">
                    
                    <mat-chip-option class="mat-chip-search" *ngIf="ft.value!==''" [removable]="true"
                        (removed)="onFilterRemoved(ft)">
                        {{ft.column_label}}:{{(ft.display_value!=""?ft.display_value:ft.value)}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-option>
                </ng-container>
            </mat-chip-listbox>
        </div> -->
        <div *ngIf="searchData.mode=='advanced'" class="adv-search-input scrollbar-primary" >
            <mat-chip-set class="mat-chip-set">
              <ng-container *ngFor="let ft of searchData.filter">
                <mat-chip
                  class="mat-chip-search"
                  *ngIf="ft.value!''"
                  [removable]="true"
                  (removed)="onFilterRemoved(ft)"
                 
                >
                <span  style="color: black; font-weight: bold;"> {{ ft.column_label }}:{{ (ft.display_value !== "" ? ft.display_value : ft.value) }}</span>
                 
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
              </ng-container>
            </mat-chip-set>
          </div>
          
        <button mat-button class="advanced" (click)="onAdvanceSearchClick()" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z">
                </path>
            </svg>
        </button>

        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
            [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen = false">
            <div class="adv-serach-contents card">
                <div class="search-row first-row">
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Search By</mat-label>
                            <mat-select name="date_type" [(ngModel)]="date_type" (selectionChange)="setSearchparams()">
                                <mat-option value="order_date">Order Date</mat-option>
                                <mat-option value="delivery_date">Delivery Date</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="search-field">
                        <div *ngIf="orderdateType; else delivery_datetype">
                            <mat-form-field appearance="fill">
                                <mat-label>Search for</mat-label>
                                <mat-select placeholder="Search For" name="search_for"
                                    [(ngModel)]="searchData.filter[1].value" (selectionChange)="setSearchparams()">
                                    <mat-option *ngFor="let opt of dateFilterOptions" value="{{opt.value}}">
                                        {{opt.label}} </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <ng-template #delivery_datetype>
                            <mat-form-field appearance="fill">
                                <mat-label>Status</mat-label>
                                <mat-select placeholder="Search For" name="search_for"
                                    [(ngModel)]="searchData.filter[1].value" (selectionChange)="setSearchparams()">
                                    <mat-option value=""> All </mat-option>
                                    <mat-option value="date-range"> Custom Date Range </mat-option>
                                    <mat-option value="today"> Today </mat-option>
                                    <mat-option value="tomorrow">Tomorrow </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>
                <div class="search-row">
                    <div class="search-field">


                        <div *ngIf="customDateRange" class=" customDateRange ">
                            <mat-form-field appearance="fill" style="width: 182px;">
                                <input matInput [matDatepicker]="picker_start" placeholder="Start Date"
                                    [(ngModel)]="startDate">
                                <mat-datepicker-toggle matSuffix [for]="picker_start"></mat-datepicker-toggle>
                                <mat-datepicker #picker_start></mat-datepicker>
                            </mat-form-field>
                            

                            <mat-form-field appearance="fill" style="padding-left: 19px;width: 182px;">
                                <input matInput [matDatepicker]="picker3" placeholder="Ending Date"
                                    [(ngModel)]="endDate">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="search-row">
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Status</mat-label>
                            <mat-select name="status" [(ngModel)]="searchData.filter[2].value">
                                <mat-option value="">All</mat-option>
                                <mat-option value="1">Placed</mat-option>
                                <mat-option value="2">Sent to shop</mat-option>
                                <mat-option value="3">Confirmed By Shop</mat-option>
                                <mat-option value="4">Sent to Kitchen</mat-option>
                                <mat-option value="7">Delivered</mat-option>
                                <mat-option value="8">Cancelled</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Customer</mat-label>
                            <input matInput placeholder="Customer" name="customer_name"
                                [(ngModel)]="searchData.filter[3].value">
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="search-row">
                    <div class="search-field">
                        <mat-form-field appearance="legacy">
                            <mat-label>Amount</mat-label>
                            <input matInput placeholder="Amount" name="total_amount"
                                [(ngModel)]="searchData.filter[4].value">
                        </mat-form-field>

                    </div>
                </div> -->

                <div class="action-button-container">
                    <button mat-button class="action-button btn-reset" (click)="onResetClick()">Reset</button>
                    <button mat-button class="action-button btn-search"
                        (click)="onSearchClick('advanced')">Search</button>
                </div>
            </div>
        </ng-template>

        <mat-menu #advsearch="matMenu" class="advsearch-options" xPosition="before">
            <button mat-menu-item class="menu-item">
                <mat-icon class="menu-icon">open_in_new</mat-icon>
                <span class="menu-label">Edit</span>
            </button>
            <button mat-menu-item class="menu-item">
                <mat-icon class="menu-icon">delete_outline</mat-icon>
                <span class="menu-label">Delete</span>
            </button>
        </mat-menu>
    </div>
    <!-- </form> -->
</div>