import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './common/services/auth.guard';
import { UserLoginComponent } from './modules/user/user-login/user-login.component';
import { OrderListComponent } from './modules/order/order-list/order-list.component';
import { OrderViewComponent } from './modules/order/order-view/order-view.component';
import { PauseOrdersListComponent } from './modules/pause-order/pause-orders-list/pause-orders-list.component';
import { CanDeactivateGuard } from './common/services/can-deactivate.guard';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { StockListComponent } from './modules/stock/stock-list/stock-list.component';
import { CalendarComponent } from './modules/calendar/calendar.component';

const routes: Routes = [

  {
    path:"dashboard",
    component:DashboardComponent,canActivate: [AuthGuard]
  },
  {
    path:"order",
    component:OrderListComponent,canActivate: [AuthGuard]
  },
  {
    path:"order-details/:id",
    component:OrderViewComponent,canActivate: [AuthGuard]
  },
  {
    path:"pause-order",component:PauseOrdersListComponent,canActivate: [AuthGuard] 
  },
  {
    path:"out-of-stock",component:StockListComponent,canActivate: [AuthGuard], 
  },
  {
    path:"shop-calendar",component:CalendarComponent,canActivate: [AuthGuard], 
  },
  
  {
    path:"",
    component:UserLoginComponent
  },{
    path:"login",
    component:UserLoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
