<div class="search-bar">
    <div class="search-input" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
        <div *ngIf="searchData.mode=='simple'" class="free-search-input">
            <input matInput type="text" placeholder='Search...' [(ngModel)]="searchText"
                (keyup.enter)="onSearchClick('simple')">
            <button class="clear" *ngIf="searchText" matSuffix (click)="searchText=''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="searchData.mode=='advanced'" class="adv-search-input scrollbar-primary">
            <mat-chip-listbox>
               
                <ng-container *ngFor="let ft of searchData.filter">
                    <mat-chip-option *ngIf="ft.value!==''" [removable]="true" (removed)="onFilterRemoved(ft)">
                        {{ft.column}}:{{ft.value}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-option>
                </ng-container>
            </mat-chip-listbox>
        </div>
        <button mat-button class="advanced" (click)="onAdvanceSearchClick()" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                    d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z">
                </path>
            </svg>
        </button>

        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
            [cdkConnectedOverlayHasBackdrop]="true" (backdropClick)="isOpen = false">
            <div class="adv-serach-contents card">
                <div class="search-row first-row">
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Code</mat-label>
                            <input matInput placeholder="Code" [(ngModel)]="searchData.filter[0].value">
                        </mat-form-field>
                    </div>
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Name</mat-label>
                            <input matInput placeholder="Name" [(ngModel)]="searchData.filter[1].value">
                        </mat-form-field>
                    </div>
                </div>
                <div class="search-row">
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Group</mat-label>
                            <input matInput placeholder="Group" [(ngModel)]="searchData.filter[2].value">
                        </mat-form-field>
                    </div>
                    <div class="search-field">
                        <mat-form-field appearance="fill">
                            <mat-label>Card Number</mat-label>
                            <input matInput placeholder="Card Number" [(ngModel)]="searchData.filter[3].value">
                        </mat-form-field>
                    </div>
                </div>

                <div class="action-button-container">
                    <button mat-button class="action-button btn-reset" (click)="onResetClick()">Reset</button>
                    <button mat-button class="action-button btn-search" (click)="onSearchClick('advanced')">Search</button>
                </div>
            </div>
        </ng-template>

        <mat-menu #advsearch="matMenu" class="advsearch-options" xPosition="before">
            <button mat-menu-item class="menu-item">
                <mat-icon class="menu-icon">open_in_new</mat-icon>
                <span class="menu-label">Edit</span>
            </button>
            <button mat-menu-item class="menu-item">
                <mat-icon class="menu-icon">delete_outline</mat-icon>
                <span class="menu-label">Delete</span>
            </button>
        </mat-menu>
    </div>

</div>