import { Injectable } from '@angular/core';
import { SideNavConfig } from './side-nav.component';

@Injectable({
  providedIn: 'root'
})

export class SideNavService {

  public sideNavConfig: SideNavConfig = {
    title: "",
    action_bar: {
      add: false,
      edit: false,
      save: false,
      update: false,
      export: false,
      back: false,
    },
    showHeader: true,
  };

  private sideNavDefaultConfig: any = {
    title: "",
    action_bar: {
      add: false,
      edit: false,
      save: false,
      update: false,
      export: false,
      back: false,
    }
  };
  page: any;

  constructor() { }

  /**
   * Sets the side nav bar
   * @param config 
   */
  set(parent: any, title: String, showHeader:boolean): void {
    this.page = parent;
    Object.assign(this.sideNavConfig, {
      title: title,
      action_bar: {
        add: this.hasAction('add'),
        edit: this.hasAction('edit'),
        save: this.hasAction('save'),
        update: this.hasAction('update'),
        export: this.hasAction('export'),
        back: this.hasAction('back'),
      },
      showHeader:showHeader,
    });
  }

  /**
   * Call the page function if defined.
   * @param action 
   */
  onAction(action: String): void {

    if (action == 'add' && this.hasAction(action)) {
      this.page.onActionAdd();
    } else if (action == 'edit' && this.hasAction(action)) {
      this.page.onActionEdit();
    } else if (action == 'save' && this.hasAction(action)) {
      this.page.onActionSave();
    } else if (action == 'update' && this.hasAction(action)) {
      this.page.onActionUpdate();
    } else if (action == 'export' && this.hasAction(action)) {
      this.page.onActionExport();
    } else if (action == 'back' && this.hasAction(action)) {
      this.page.onActionBack();
    }
  }

  /**
   * Checks the action defined or not
   * @param action 
   * @returns 
   */
  hasAction(action: String): boolean {

    var isDefined = false;
    if (action == 'add' && this.page.onActionAdd != undefined) {
      isDefined = true;
    } else if (action == 'edit' && this.page.onActionEdit != undefined) {
      isDefined = true;
    } else if (action == 'save' && this.page.onActionSave != undefined) {
      isDefined = true;
    } else if (action == 'update' && this.page.onActionUpdate != undefined) {
      isDefined = true;
    } else if (action == 'export' && this.page.onActionExport != undefined) {
      isDefined = true;
    } else if (action == 'back' && this.page.onActionBack != undefined) {
      isDefined = true;
    }
    return isDefined;
  }


}
