<div class="pop-up-export">
<div mat-dialog-title class="dialog-title">{{confirmMessage}}</div>
<div (click)="dialogRef.close(false)"><span class="closeIconExport">
  x
</span></div>
<hr>
<div mat-dialog-content class="message-content">
  {{contentMessage}}
</div>


<div class="confirm-btn"><button mat-raised-button color="primary" class="button-cancel" (click)="dialogRef.close(false)">CANCEL</button>
  <button mat-raised-button color="primary" class="button-ok" (click)="dialogRef.close(true)"
    cdkFocusInitial>CONFIRM</button></div>
</div>