

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { SESSION_SERVICE_TOCKEN } from '../../app.constants';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  dialogReff: MatDialogRef<ConfirmDialogComponent> | null = null;

  constructor(public dialog: MatDialog, private storage: StorageService, public router: Router,) { }
  /**
   * Sets the tocken to storage
   * @param token 
   */
  private setToken(token: string) {
    this.storage.saveToSession(SESSION_SERVICE_TOCKEN, token);
  }
  /**
   * Returnt the tocken from storage
   * @returns 
   */
  public getToken(): string {
    return this.storage.loadFromSession(SESSION_SERVICE_TOCKEN);
  }
  /**
   * Validates the tocken
   * @param token 
   */
  public validateToken(token: string) {
    /** To Do validates the tocken if neceessary.*/
    this.setToken(token);
  }
  // Returns true when user is looged in 
  get isLoggedIn(): boolean {
    const user = JSON.parse(this.storage.loadFromSession('user'));
    return (user !== null) ? true : false;
  }
  /**
* logout
* @returns 
*/
  logout() {
    const user = JSON.parse(this.storage.loadFromSession('user'));
    if(user!==null){
    var conformationMsg = " Are you sure you want to leave?";
    this.dialogReff = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      width: "470px",
      height: "180px",
    });
    this.dialogReff.componentInstance.confirmMessage = conformationMsg;
    this.dialogReff.afterClosed().subscribe(result => {
      if (result) {
        const user = JSON.parse(this.storage.loadFromSession('user'));
        localStorage.setItem('shop_id', user.shop_id);
        this.storage.saveToSession('user', null);
        this.router.navigate(['/login']);
      }
      this.dialogReff = null;
    });

  }
}
}
