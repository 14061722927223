<div class="main-list">

    <div class="search-page-bar " style="height: 100%;">
        <ng-container *ngIf="is_holiday(); then shopclosed; else shopopen">
        </ng-container>
        <ng-template #shopclosed>
            <div class="sub-heading" style="background-color: #ea2100;">
                <h3 class="pause-status" >SHOP IS CLOSED</h3>
        
                <div class="pause-btn"> <button mat-flat-button color="warn" [disabled]="is_holiday()"></button>
                </div>
            </div>
        </ng-template>
        <ng-template #shopopen>
    
        <ng-container *ngIf="pausedStatus(); then paused; else resume">
        </ng-container>
        <ng-template #resume>
            <div class="sub-heading">
                <h3 class="pause-status">ONLINE SALES IS ON</h3>
        
                <div class="pause-btn"> <button mat-flat-button color="warn" (click)="confirmPauseOrder()">PAUSE ONLINE
                        SALES</button>
                </div>
            </div>
        </ng-template>
        <ng-template #paused>
            <div class="sub-heading_resume ">
                <h3 class="blink_me pause-status">ONLINE SALES IS TEMPORARILY PAUSED TILL {{pausedTime}}</h3>

                <div class="resume-btn"> <button mat-flat-button (click)="ResumePauseOrder()"
                        style="background-color: darkgreen;color: white;">RESUME ONLINE ORDERS</button>
                </div>
            </div>

        </ng-template>
        </ng-template>
        <div style="width:70%; min-width: 815px;">

            <div class="searchpage-bar" style="width:100%">
                <div style="width: 25%;text-align: left;margin-left: 5px;">
                    <h4>Pause Order History</h4>
                </div>
                <!-- <label style="margin-top: -5px;">Date</label> -->
                <mat-form-field style="width: 157px;margin-top:5px;padding-top: 5px;"
                    [ngStyle]="{'color':hasError === true  ? 'red' : '' }">
                    <mat-label>Date From </mat-label>
                    <input matInput [matDatepicker]="picker_start" placeholder="Date From" [(ngModel)]="timeFrom">
                    <mat-datepicker-toggle matSuffix [for]="picker_start"
                        [ngStyle]="{'color':hasError === true  ? 'red' : '' }"></mat-datepicker-toggle>
                    <mat-datepicker #picker_start></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="padding-left: 19px;width: 157px;margin-top:5px;padding-top: 5px;"
                    [ngStyle]="{'color':hasError === true  ? 'red' : '' }">
                    <mat-label>Date To</mat-label>
                    <input matInput [matDatepicker]="picker3" placeholder="Date To" [(ngModel)]="timeTo">
                    <mat-datepicker-toggle matSuffix [for]="picker3"
                        [ngStyle]="{'color':hasError === true  ? 'red' : '' }"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
                <button mat-flat-button color="primary" (click)="onSearchClick()" class="mat-focus-indicator mat-flat-button mat-button-base mat-primary">GO</button>
                <div style=" margin-left: auto;flex-direction: row;">
                    <div style="text-align: right;">
                        <button mat-flat-button style="margin-right: 5px;" (click)="navPage(-1)">
                            <mat-icon>arrow_left</mat-icon>
                        </button>
                        <button mat-flat-button (click)="navPage(1)">
                            <mat-icon>arrow_right</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-list-contianer" style="width: 70%; min-width: 815px;">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" class="spinner" size="medium" color="#1010ee"
                type="ball-clip-rotate-pulse">
            </ngx-spinner>
            <div class="table-list-header-container table-list-row">
                <div class="table-list-col header sortable col-sl">
                    <div class="col-content">Sl#</div>
                </div>
                <div class="table-list-col header sortable col-date">
                    <div class="col-date">Date</div>
                </div>
                <div class="table-list-col header sortable col-timefrom">
                    <div class="col-date">Time From</div>
                </div>
                <div class="table-list-col header sortable col-timeto">
                    <div class="col-date">Time To</div>
                </div>
                <div class="table-list-col header col-duration">
                    <div class="col-date" style="text-align: center;">Duration(H)</div>
                </div>
            </div>
            <div class="table-list-row-container" style="max-height: unset;">
                <ng-container *ngIf="pauseOrderList?.length>0;then pauseorderList else noData"></ng-container>
                <ng-template #pauseorderList>
                    <div *ngFor="let order of pauseOrderList let i=index;" class="table-list-row data-row">
                        <div class="table-list-col data col-sl">
                            <div class="col-content">{{i + pageData.offset}}</div>
                        </div>
                        <div class="table-list-col data col-date">
                            <div class="col-content">{{order.date}}</div>
                        </div>
                        <div class="table-list-col data col-timefrom">
                            <div class="col-content"> {{ order.from_time}}</div>
                        </div>
                        <div class="table-list-col data col-timeto">
                            <div class="col-content">{{ order.to_time}} </div>
                        </div>
                        <div class="table-list-col data col-duration">
                            <div class="col-content">{{order.duration}}
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template #noData>
                    <div class="nodata"> No pause order history records found.</div>
                </ng-template>
            </div>
        </div>
    </div>
</div>