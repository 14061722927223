import { Component } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { CanDeactivateState } from './common/services/can-deactivate.guard';
import { StorageService } from './common/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'oo-shop-admin';
 
  constructor( private storage: StorageService,readonly router: Router,) { 


  }

  isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user'));
    const user = JSON.parse(this.storage.loadFromSession('user'));
     return (user !== null) ? true : false;
    
   }
}

