
<ng-container *ngIf="isLoggedIn(); then dashboard; else login">
</ng-container>
<ng-template #dashboard>
    <app-side-nav>
  
    </app-side-nav>
</ng-template>

<ng-template #login>
    <app-user-login></app-user-login>
</ng-template>