<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-drawer " fixedInViewport [attr.role]="(isHandset) ? 'dialog' : 'navigation'"
  [mode]="'side'" [opened]="(isHandset) === false"  
    >
    <!-- [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" -->
    <mat-toolbar class="logo-toolbar" style="color: white;">
      <img class="logo-img" src="assets/images/logo/logo.png">
      <div class="logo-title">St Pierre's Sushi<br>
      </div>

    </mat-toolbar>
  
    <mat-nav-list>
      <mat-nav-list>
        <!-- <mat-list-item [routerLink]="['dashboard']" [routerLinkActive]="'selected-menu-option'">
          <mat-icon mat-list-icon style="color: white;"> dashboard</mat-icon>
          <h4 mat-line >DASHBOARD</h4>
        </mat-list-item> -->
        <mat-list-item [routerLink]="['dashboard']" [routerLinkActive]="'selected-menu-option'">
          <div class="icon-text-container">
            <mat-icon mat-list-icon >dashboard</mat-icon>
            <h4 mat-line style="padding-top:18px;">DASHBOARD</h4>
          </div>
        </mat-list-item>

        <mat-list-item [routerLink]="['order']" [routerLinkActive]="'selected-menu-option'">
          <div class="icon-text-container">
          <mat-icon mat-list-icon> list</mat-icon>
       <h4 mat-line style="color: white; padding-top:18px;">ORDERS</h4>
          </div>
        </mat-list-item>

        <mat-list-item [routerLink]="['pause-order']" [routerLinkActive]="'selected-menu-option'">
          <div class="icon-text-container">
          <mat-icon matListIcon>pause_presentation</mat-icon>
           <h4 mat-line style="color: white; padding-top:18px;">PAUSE ONLINE SALES</h4>
          </div>

        </mat-list-item>
        <mat-list-item  [routerLink]="['out-of-stock']" [routerLinkActive]="'selected-menu-option'"> 
          <div class="icon-text-container">
          <mat-icon mat-list-icon>domain</mat-icon>
         <h4 mat-line style="color: white; padding-top:18px;">SET OUT OF STOCK</h4> 
          </div>
        </mat-list-item>
        <mat-list-item  [routerLink]="['shop-calendar']" [routerLinkActive]="'selected-menu-option'"> 
          <div class="icon-text-container">
          <mat-icon mat-list-icon>calendar_today</mat-icon>
           <h4 mat-line style="color: white; padding-top:18px;">CALENDAR</h4> 
          </div>
        </mat-list-item>
        
        <mat-list-item>
          <div class="icon-text-container">
          <mat-icon matListIcon  (click)="logout()"> exit_to_app</mat-icon>
          <h4 mat-line style="color: white; padding-top:18px;" >LOGOUT</h4> 
          </div>
        </mat-list-item>
        
      </mat-nav-list>
    </mat-nav-list>
    <div *ngIf="isDrawerOpen" class="copyright-text">{{copyRightText}}<br>{{copyRightTextcnt}}</div>
  </mat-sidenav>

  <mat-sidenav-content >
    <mat-toolbar *ngIf="sideNavConfig?.showHeader" color="primary" class="toolbar-account">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button
        (click)="drawer.toggle();isDrawerOpen=!isDrawerOpen">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>{{sideNavConfig?.title}}</span>
      <div class="form-action-container">
        <button *ngIf="sideNavConfig?.action_bar.add" class="btn-action add" mat-button (click)="sideNavService.onAction('add');">Add</button>
        <button *ngIf="sideNavConfig?.action_bar.edit" class="btn-action edit" mat-button  (click)="sideNavService.onAction('edit');">Edit</button>
        <button *ngIf="sideNavConfig?.action_bar.save" class="btn-action save" mat-button  (click)="sideNavService.onAction('save');">Save</button>
        <button *ngIf="sideNavConfig?.action_bar.update" class="btn-action update" mat-button  (click)="sideNavService.onAction('update');">Update</button>
        <button *ngIf="sideNavConfig?.action_bar.export" class="btn-action export" mat-button (click)="sideNavService.onAction('export');">Export</button>
        <button *ngIf="sideNavConfig?.action_bar.back" class="btn-action back" mat-button  (click)="sideNavService.onAction('back');">Back</button>
      </div>
      <div class="account-info" [matMenuTriggerFor]="menu">
        <mat-icon>person</mat-icon>
      </div>
      <!-- <button mat-button [matMenuTriggerFor]="menu">Menu</button> -->
      <mat-menu #menu="matMenu" class="account-info-menu">
        <div class="acc-notice">
          <div class="logo-shop-title">{{shop_name}}</div>
          <span>Welcome {{user.username}}</span>
          <div>
          <span>Last login</span>
          <span> {{user.lastlogin_date}}</span>
        </div>
        </div>
        <!-- <button mat-menu-item>Item 1</button> -->
        <button mat-menu-item (click)="logout()">Log Out <mat-icon matListIcon> exit_to_app</mat-icon></button>
      </mat-menu>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div [ngClass]="sideNavConfig?.showHeader?'page-content':'page-content-full'">
      <router-outlet></router-outlet>
    </div>
    
    <!-- <ng-content></ng-content> -->
  </mat-sidenav-content>
</mat-sidenav-container>