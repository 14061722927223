import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserApiService } from '../user-api.service';
import { AuthService } from '../../../common/services/auth.service';
import { SnackBarService } from '../../../common/services/snack-bar.service';
import { StorageService } from '../../../common/services/storage.service';



@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

  loginForm: FormGroup;
  response: any;
  ShopList: any;
  selectedShop: any;

  constructor(
    public http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    private userapiService: UserApiService,
    private spinner: NgxSpinnerService,
    private storage: StorageService,
    private authService: AuthService,
    private snackBarService: SnackBarService
  ) {
    this.loginForm = this.formBuilder.group({
      'shopId': ['', Validators.required],
      'userName': ['', Validators.required],
      'password': ['', Validators.required]
    });
    this.response = { 'status': '', 'message': '' };
    this.getShopList();
  }

  ngOnInit(): void {
    
    this.selectedShop = localStorage.getItem('shop_id');
    // this.loginForm.get('shopId').setValue(this.selectedShop);

    // skip login from exiting session
    const authenticationStatus = this.authService.isLoggedIn;

    if (authenticationStatus === true) {
      this.router.navigate(['/dashboard']);
    }
  }


  /**
   * user login function
   *  
   */

  loginUser(): void {
    this.spinner.show();
    this.response.status = null;
    const requestParams = this.loginForm.value;
    if (this.loginForm.invalid) {
      return;
    } else {
      var loginDetails = {
        shop_id: requestParams.shopId,
        username: requestParams.userName,
        password: requestParams.password,

      }

      this.userapiService.userLogin(loginDetails).subscribe(
        (response: any) => {
          if (response.status === 0) {
            this.router.navigate(['/dashboard']);
            this.authService.validateToken(response.token);
            // var doc:any; 
            //  doc=document.documentElement;
            // doc.webkitRequestFullScreen();

          } else {
            this.storage.saveToSession('user', null);
            this.response.status = 401;
            this.response.message = response.message;
          }
          
        },
        (error: any) => {
          this.snackBarService.error("Invalid login details. Please try again.");
        }
      );
    }
  }

  

  /**
  * list active shops
  * 
  */
  getShopList() {
    this.userapiService.getShopList().subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.ShopList = resp.data.shops;
        } else {
          this.snackBarService.error(resp.message);
        }
      },
      (error: any) => {
        this.snackBarService.error("Failed to list shops.");
      }

    );

  }

}
