import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import  moment from 'moment';
import { StorageService } from '../../../../common/services/storage.service';


export interface ColFilter {
  column: String,
  column_label?: String,
  operator: String,
  value: any,
  display_value?: any
}
export interface SearchData {
  mode: String,
  filter: ColFilter[]
};

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})

export class OrderSearchComponent implements OnInit, OnChanges {
  searchText: any;
  isOpen: boolean = false;
  public customDateRange: boolean = false;
  public orderdateType: boolean = true;
  public dateFilter: any = "today";
  public date_type: any = "order_date";
  public sdt: string = "";
  public edt: string = "";

  public dateFilterOptions = [
    { value: "", label: "All" },
    { value: "date-range", label: "Custom Date Range" },
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "thisweek", label: "This Week" },
    { value: "lastweek", label: "Last Week" },
    { value: "thismonth", label: "This Month" }]

  @Input() searchData!: SearchData;
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  startDate: any;
  endDate: any;
  buildRqt: any;

  constructor(private storage: StorageService, private formBuilder: FormBuilder, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-NZ');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setPageData();
  }

  setPageData() {
    this.setSearchparams();
  }

  ngOnInit(): void {


  }

  /**
   * When advance search button cliked,
   * need to set up the search data
   */
  onAdvanceSearchClick() {
    this.isOpen = !this.isOpen;
    if (this.searchData.mode == "simple") {
      this.searchData.filter?.forEach(ft => {
        ft.value = '';
      });
    }
  }

  /**
   * Reset click.
   * Clear the serch data and set it back to simple search mode
   */
  onResetClick(): void {
    this.customDateRange = false;
    this.searchData.mode = "simple",
      this.searchData.filter?.forEach(ft => {
        ft.value = "";
      });
    this.searchText = "";
  }


  /**
   * When filteres are removed update the searchdata
   * @param ft 
   */
  onFilterRemoved(ft: any) {
    ft.value = '';
    var removedCol = this.searchData.filter?.find(f => f.column == ft.column);
    if (removedCol !== undefined) removedCol.value = '';
    var searchMode = (this.searchData.filter?.find(f => f.value !== '') !== undefined) ? 'advanced' : 'simple';
    if (this.searchData.filter[1].value === "date-range") {
      this.customDateRange = true;
    } else {
      this.customDateRange = false;
    }
    this.onSearchClick(searchMode);
  }

  /**
   * On search button click
   */
  onSearchClick(mode: any): void {
    this.isOpen = false;
    this.searchData.mode = mode;
    this.searchData.filter[0].value = this.date_type;

    if (this.startDate != "" && this.endDate != "" && this.searchData.filter[1].value == "date-range") {
      this.searchData.filter[5].value = moment(this.startDate).format("DD-MM-YYYY");
      this.searchData.filter[6].value = moment(this.endDate).format("DD-MM-YYYY");
    } else {

      this.startDate = "";
      this.endDate = "";

    }

    var searchJson: SearchData = JSON.parse(JSON.stringify(this.searchData));

    if (mode == "simple") {
      searchJson.filter?.forEach(ft => {
        ft.value = this.searchText;
        ft.operator = 'like'
      });
    } else {
      searchJson.filter?.forEach(ft => {
        ft.operator = '='
      });
    }

    this.onSearch.emit(searchJson);
  }

  setSearchparams(): void {

    this.searchData.filter[0].value = this.date_type;
    this.searchData.filter[0].display_value = (this.date_type == "order_date" ? "Order Date" : "Delivery Date");

    this.searchData.filter[1].display_value = this.dateFilterOptions?.find(
      (opt) => opt.value === this.searchData.filter[1].value
    )?.label;
    if (this.searchData.filter[1].value !== "date-range") {
      this.searchData.filter[5].value = '';
      this.searchData.filter[6].value = '';
    } else {
      this.startDate = (this.searchData.filter[5].value == '' ? new Date() : new Date(moment(this.searchData.filter[5].value, "DD-MM-YYYY").format('MM-DD-YYYY')));
      this.endDate = (this.searchData.filter[6].value == '' ? new Date() : new Date(moment(this.searchData.filter[6].value, "DD-MM-YYYY").format('MM-DD-YYYY')));
      this.searchData.filter[5].value = moment(this.startDate).format("DD-MM-YYYY");
      this.searchData.filter[6].value = moment(this.endDate).format("DD-MM-YYYY");
    }

    this.customDateRange = (this.searchData.filter[1].value === "date-range");
    this.orderdateType = (this.searchData.filter[0].value == "order_date");

  }

}

