import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SideNavService } from './side-nav.service';
import { Router } from '@angular/router';

import { AuthService } from '../common/services/auth.service';
import { UserApiService } from '../modules/user/user-api.service';
import { StorageService } from '../common/services/storage.service';

/**
 * Interface for Add button action.
 * Implent this interface when add button is implemented in the page
 */
export interface OnActionAdd {

  /**
   * Call back function for add button
   */
  onActionAdd(): void;
}

/**
 * Interface for Edit button action.
 * Implent this interface when edit button is implemented in the page
 */
export interface OnActionEdit {

  /**
   * Call back function for edit button
   */
  onActionEdit(): void;
}

/**
 * Interface for Save button action.
 * Implent this interface when save button is implemented in the page
 */
export interface OnActionSave {

  /**
   * Call back function for save button
   */
  onActionSave(): void;
}

/**
 * Interface for Update button action.
 * Implent this interface when update button is implemented in the page
 */
export interface OnActionUpdate {

  /**
   * Call back function for update button
   */
  onActionUpdate(): void;
}

/**
 * Interface for Export button action.
 * Implent this interface when export button is implemented in the page
 */
export interface OnActionExport {

  /**
   * Call back function for export button
   */
  onActionExport(): void;
}

/**
 * Interface for Back button action.
 * Implent this interface when back button is implemented in the page
 */
export interface OnActionBack {

  /**
   * Call back function for export button
   */
  onActionBack(): void;
}



/**
 * Interface for Side nav configuration
 */
export interface SideNavConfig {
  title: String;
  action_bar?: {
    add?: boolean,
    edit?: boolean,
    save?: boolean,
    update?: boolean,
    export?: boolean,
    back?: boolean,
  },
  showHeader: boolean,
};


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SideNavComponent implements OnInit {

  public sideNavConfig: any;
  public selected: any;

  copyRightText: string = "Copyrights (c) Mochi Digital Limited."
  copyRightTextcnt: string = "All rights resered.";
  isDrawerOpen: Boolean = true;
  isHandset:Boolean =true;

  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.TabletLandscape)
  //   .pipe(
  //     map(result => result.matches),
  //     shareReplay()
  //   );

  user: any;
  shop_name: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private userapiService: UserApiService, public sideNavService: SideNavService, private router: Router, private storage: StorageService, private AuthService: AuthService) {
    this.sideNavConfig = sideNavService.sideNavConfig;
    this.user = JSON.parse(this.storage.loadFromSession('user'));
    this.shop_name = this.user.shop_name;
    this.selected = 'order';

  }

  ngOnInit(){

    this.isHandset =true;
    this.isDrawerOpen = !this.isHandset;
    
  }

  logout() {
    const user = JSON.parse(this.storage.loadFromSession('user'));
    this.userapiService.updateLastlogin(user).subscribe(
      (response: any) => {

      },
    );

    this.AuthService.logout();

  }

  /**
   * 
   * @param menu Menu selection
   */
  onMenuSelected(menu: any) {
    this.selected = menu;
  }
}
