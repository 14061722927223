<div class="pop-up-export">
    <div mat-dialog-title>LEAVE WITHOUT SAVING</div>
    <div (click)="onClickCancel()"><span class="closeIconExport">
            X
        </span></div>
    <hr>
    <div mat-dialog-content>
        What would you like to do?
    </div>


    <div class="confirm-btn"><button mat-raised-button color="primary" class="button-cancel"
            (click)="onClickCancel()">STAY ON FORM</button>
        <button mat-raised-button color="primary" class="button-ok" (click)="onClickConfirm()" cdkFocusInitial>LEAVE
            WITHOUT SAVING</button>
    </div>
</div>