<div class="order-list">
  <div class="table-list-contianer">

    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-pulse">
    </ngx-spinner>

    <div class="page-content" *ngIf="detailProvider">
      <div class="page-content-r1">
        <div class="block">
          <div class="box-header">
            Order Details
          </div>
          <div class="box-content">
            <div class="item-list">
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">folder</mat-icon>
                </div>
                <div class="item-label">{{ detailProvider.orderhdrData.invoice }}</div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">shop</mat-icon>
                </div>
                <div class="item-label">{{ detailProvider.orderhdrData.shop_name }}</div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">date_range</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderhdrData.order_time | date: "MMM d, y, h:mm a" }}
                </div>
              </div>
              <div class="item">
                <!-- <div class="item-icon"> <mat-icon class="order-dtl-icon">payment</mat-icon> </div>
                  <div class="item-label"> Cash on Delivery </div> -->
              </div>
              <div class="item">
                <!-- <div class="item-icon"> <mat-icon class="order-dtl-icon">local_shipping</mat-icon> </div>
                  <div class="item-label"> Shipping User </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="box-header">
            Customer Details
          </div>
          <div class="box-content">
            <div class="item-list">
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">account_circle</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderhdrData.title }}
                  {{ detailProvider.orderhdrData.first_name }}
                  {{ detailProvider.orderhdrData.last_name }}
                </div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">location_city</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderhdrData.address }}
                </div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">email</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderhdrData.email }}
                </div>
              </div>
              <div class="item">
                <div class="item-icon">
                  <mat-icon class="order-dtl-icon">phone</mat-icon>
                </div>
                <div class="item-label">
                  {{ detailProvider.orderhdrData.phone_number }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="block ">
          <div class="box-header">
            Status
          </div>
          <div class="timeline">
            <ul >
              <li class="list-item" *ngFor="
                      let item of detailProvider.orderHistorySampleDto | reversePipe ">
                <h3 class="order-label">
                  <span style="border: 1px solid;" class="order" [style.background-color]="item.bg_color"
                    [style.color]="item.fg_color" [style.border-color]="item.border_color">
                    {{ item.display_text }}
                  </span>
                  <!-- <p class="date">{{ item.updateAt | date: "medium" }}</p> -->
                  <p class="date">{{ item.processedAt }}</p>
                </h3>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="order-remmarks" *ngIf="detailProvider.orderhdrData.remarks !==undefined && detailProvider.orderhdrData.remarks !==null && detailProvider.orderhdrData.remarks.length>0">
        <div [innerHtml]="getOrderRemarks(detailProvider)"></div>
       </div>
      <div class="page-content-r2">
        <div class="order-table">
          <div class="invoice-model">
            <table class="table table-bordered invoice-table-custom">
              <thead class="invoice">
                <tr class="head">
                  <td class="text-left">Item</td>
                  <td class="text-left">Item Category</td>
                  <td style="text-align: right">Quantity</td>
                  <td style="text-align: right">Unit Price</td>
                  <td style="text-align: right">Total</td>
                </tr>
              </thead>
              <tbody class="tbody">
                <ng-container *ngFor="let item of detailProvider.orderDtls">
                  <tr style="font-weight: bold ;">
                    <td class="text-left">{{ item.name }}</td>
                    <td class="text-left">{{ item.sub_class_name }}</td>
                    <td style="text-align: right">{{ item.qty }}</td>
                    <td style="text-align: right">
                      {{ item.fixed_price | currency }}
                    </td>
                    <td style="text-align: right">{{ item.item_total | currency }}</td>
                  </tr>
                  <!-- <ng-container *ngIf="item.customization !== null && item.customization !== undefined "> -->
                  <tr *ngFor="let cust of getCustomizations(item)">
                    <td colspan=2 class="text-left" style="padding-left: 35px;"> <!--<i style="margin-right: 10px;"
                        class="fa fa-caret-right" aria-hidden="true"></i>-->
                        
                        <mat-icon class="cust-sub">arrow_right</mat-icon> {{ cust.name }}</td> 
                    <!-- <td class="text-left">{{ item.subClassName }}</td> -->
                    <td style="text-align: right">{{ cust.qty }}</td>
                    <td style="text-align: right">
                      {{ cust.price | currency }}
                    </td>
                    <td style="text-align: right">{{ cust.total | currency }}</td>
                  </tr>
                  <!-- </ng-container> -->
                  <tr *ngIf="item.remarks !==undefined && item.remarks.length>0">
                    <td colspan="5">
                      **
                      {{item.remarks}}
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <td colspan="4" style="text-align: right">Total Amount</td>
                  <td style="text-align: right">
                    {{ detailProvider.orderhdrData.total_amount | currency }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="text-align: right">Discount</td>
                  <td style="text-align: right">
                    {{
                      detailProvider.orderhdrData.total_amount - detailProvider.orderhdrData.total_amount_paid
                      | currency
                      }}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="text-align: right">Net Amount</td>
                  <td style="text-align: right">
                    {{ detailProvider.orderhdrData.total_amount_paid | currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>