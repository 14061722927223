<div class="order-list">


  <div class="row r1">
    <div class="column-r1">
      <div class="card card-today">
        <div class="card-heading">
          <span><b>TODAY</b></span>
        </div>
        <div class="today-div">
          <div class="common-style"> ${{todayTotalAmt | number: '.2'}} </div>
          <div class="order-line"></div>
          <div class="common-style">{{todayOrderCnt | number: '.0'}}</div>

        </div>
        <div class="deliverd-sub-div">
          <div class="left-div-deliverd">
            <div class="" style="text-align: center;margin-bottom: 10px;">To deliver</div>
            <div class="deliverd-today"> ${{toDeliverTotalAmt | number: '.2'}} </div>
            <div class="deliverd-line"></div>
            <div class="deliverd-today">{{toDeliverOrders | number: '.0'}}</div>

          </div>
          <div class="right-div-deliverd">
            <div class="" style="text-align: center;margin-bottom: 10px;">Delivered</div>
            <div class="deliverd-today" style="text-align: center"> ${{todayDeliveredTotalAmt | number: '.2'}} </div>
            <div class="deliverd-line" style="text-align: center"></div>
            <div class="deliverd-today" style="text-align: center">{{todayDeliveredOrderCnt | number: '.0'}}</div>
          </div>
        </div>



      </div>
    </div>

    <div class="column-r1">
      <div class="card card-thisweek">
        <div class="card-heading">
          <span><b>THIS WEEK </b></span>
        </div>
        <div class="today-div">
          <div class="common-style"> ${{thisWeekTotalAmt | number: '.2'}} </div>
          <div class="order-line"></div>
          <div class="common-style">{{thisWeekOrderCnt | number: '.0'}}</div>

        </div>
      </div>
    </div>

    <div class="column-r1">
      <div class="card card-lastweek">
        <div class="card-heading">
          <span><b>THIS MONTH </b></span>
        </div>
        <div class="today-div">
          <div class="common-style"> ${{thisMonthTotalAmt | number: '.2'}} </div>
          <div class="order-line"></div>
          <div class="common-style">{{thisMonthOrderCnt | number: '.0'}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <ng-container *ngIf="is_holiday(); then shopclosed; else shopopen">
    </ng-container>

   
    <ng-template #shopclosed>
      <div class="column-r2">
      <div class="card pause-order-div" >
        <div class="card-heading">
          <span><b>ONLINE SALES </b></span>
        </div>
       
        <div class="sales-status-indecator-closed" >
          <span>CLOSED</span>
        </div>
        <p class="online-content-div ">SHOP IS CLOSED</p>
        <div class="pause-btn" > <button mat-flat-button color="warn"     [disabled]="is_holiday()"><b>PAUSE ONLINE
            SALES</b></button>
        </div>
        <div class="history-div"><a [routerLink]="['../pause-order']" style="color:white;">HISTORY</a></div>
      </div>
      </div>
    </ng-template>
    <ng-template #shopopen>
      <ng-container *ngIf="pausedStatus(); then paused; else resume">
      </ng-container>
    <ng-template #resume>
      <div class="column-r2">
        <div class="card pause-order-div">
          <div class="card-heading">
            <span><b>ONLINE SALES </b></span>
          </div>
          
          <div class="sales-status-indecator on" >
            <span>SELLING</span>
          </div>
          <!-- <p class="online-content-div ">Online sales is on now. Click below button to change the status</p> -->
          <p class="online-content-div ">Online sales is on now. Click below button to change the status</p>
          <div class="pause-btn" > <button mat-flat-button color="warn" [disabled]="is_holiday()"  (click)="confirmPauseOrder()"><b>PAUSE ONLINE
              SALES</b></button>
          </div>
          <div class="history-div"><a [routerLink]="['../pause-order']" style="color:white;">HISTORY</a></div>
        </div>
       
      </div>
    </ng-template>
    <ng-template #paused>
      <div class="column-r2">
        <div class="card pause-order-div" >
          <div class="card-heading">
            <span><b>ONLINE SALES </b></span>
          </div>
          <div class="sales-status-indecator paused">
            <span class="blink_me">PAUSED</span>
          </div>
          <div class="online-content-div blink">
            <div>ONLINE SALES IS TEMPORARILY PAUSED  </div>
            <div class="pause-time-display"> TILL {{pausedTime}}</div>
          </div>
          <div class="resume-btn"> <button mat-flat-button style="background-color: darkgreen;color: white;"
              (click)="ResumePauseOrder()" ><b>RESUME NOW</b></button>
          </div>
          <div class="history-div"><a [routerLink]="['../pause-order']" style="color:white;">HISTORY</a></div>
        </div>
        
      </div>
    </ng-template>
  </ng-template>

    <ng-container *ngIf="outOfStock?.length>0;then showCount else noData"></ng-container>

    <ng-template #showCount>
      <div class="column-r2">
        <div class="card out-of-stock-div">
          <div class="card-heading">
            <span><b>OUT OF STOCK ITEMS </b></span>
          </div>
          <div class="outof-stock-indecator">
            <span>{{totalStockcount}}</span>
          </div>
          <div class="stock-content-div">There are {{totalStockcount}} items set as out of stock for today. Click on 
            <b>MORE</b> to view the details.</div>
          <div class="more-btn"><a [routerLink]="['../out-of-stock']" style="color:white;">MORE</a></div>
        </div>
      </div>
    </ng-template>
    <ng-template #noData>
      <div class="column-r2">
        <div class="card out-of-stock-div">
          <div class="card-heading">
            <span><b>OUT OF STOCK ITEMS </b></span>
          </div>
          <div class="outof-stock-indecator">
            <span>{{totalStockcount}}</span>
          </div>
          <div class="stock-content-div">There are no items set as out of stock for today.  Click on 
            <b>MORE</b> to view the details.</div>
          <div class="more-btn"><a [routerLink]="['../out-of-stock']" routerLinkActive="active"
              style="color:white;">MORE</a></div>
        </div>
      </div>
    </ng-template>
