<div class="order-list">
    <!-- <div class="order-summary-info">
        <div class="total-amount-div summary-panel">
            <div class="total-amount-title-div">
                <span>Total Amount</span>
            </div>
            <div class="total-amount-value-div">
                <span class="total-amo">${{totalAmount | number: '.2'}}</span>
            </div>
        </div>
        <div class="total-orders-div summary-panel">
            <div class="total-orders-title-div">
                <span>Total Orders</span>
            </div>
            <div class="total-orders-value-div">
                <span class="total-val">{{numOfOrders | number: '.0'}}</span>
            </div>
        </div>
    </div> -->
    <div class="search-page-bar card">
        <app-order-search class="user-serach-bar" [searchData]="searchData" (onSearch)="onSearch($event)">
        </app-order-search>
        <div class="order-summary-info">
            <div class="total-amount-div summary-panel" (click)="toggleAmount(false)">
                <!-- <div class="total-amount-title-div">
                    <span>Total Amount</span>
                </div> -->
                <div class="total-amount-value-div">
                    <span class="total-amo">${{totalAmount | number: '.2'}}</span>
                </div>
            </div>
            <div  class="total-orders-div summary-panel" (click)="toggleAmount(true)">
                <!-- <div class="total-orders-title-div">
                    <span>Total Orders</span>
                </div> -->
                <div class="total-orders-value-div">
                    <span class="total-val">{{numOfOrders | number: '.0'}}</span>
                </div>
            </div>
        </div>
        <app-paginator class="user-page-bar" [pageData]="pageData" (changed)="onPagination($event)"></app-paginator>
    </div>
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" class="spinner" size="medium" color="#1010ee"
        type="ball-clip-rotate-pulse">
    </ngx-spinner>

    <div class="table-list-contianer">

        <div class="table-list-header-container table-list-row">
            <div class="table-list-col header sortable col-invoice" [matMenuTriggerFor]="menuName">
                <div class="col-content"
                    [ngClass]="{'sort-icon-desc':sort.order=='desc' && sort.column=='invoice', 'sort-icon-asc':sort.order=='asc'  && sort.column=='invoice'}">
                    Invoice</div>
                <div class="col-options">::</div>
                <mat-menu #menuName="matMenu" class="menu-container">
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'invoice',action:'sort',order:'asc'})">
                        <span style="font-size: 20px; margin-right: 10px;">&#8593;</span>
                        <span class="menu-label">Sort Ascending</span>
                    </button>
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'invoice',action:'sort',order:'desc'})">
                        <span style="font-size: 20px;margin-right: 10px;">&#8595;</span>
                        <span class="menu-label">Sort Descending</span>
                    </button>
                </mat-menu>
            </div>
            <div class="table-list-col header sortable col-cusname" [matMenuTriggerFor]="menuGroup">
                <div class="col-content"
                    [ngClass]="{'sort-icon-desc':sort.order=='desc' && sort.column=='customer_name', 'sort-icon-asc':sort.order=='asc'  && sort.column=='customer_name'}">
                    Customer Name</div>
                <div class="col-options">::</div>
                <mat-menu #menuGroup="matMenu" class="menu-container">
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'customer_name',action:'sort',order:'asc'})">
                        <span style="font-size: 20px; margin-right: 10px;">&#8593;</span>
                        <span class="menu-label">Sort Ascending</span>
                    </button>
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'customer_name',action:'sort',order:'desc'})">
                        <span style="font-size: 20px;margin-right: 10px;">&#8595;</span>
                        <span class="menu-label">Sort Descending</span>
                    </button>
                </mat-menu>
            </div>
            <div class="table-list-col header sortable col-amt" [matMenuTriggerFor]="menuCard">
                <div class="col-content"
                    [ngClass]="{'sort-icon-desc':sort.order=='desc' && sort.column=='total_amount', 'sort-icon-asc':sort.order=='asc'  && sort.column=='total_amount'}">
                    Amount</div>
                <div class="col-options">::</div>
                <mat-menu #menuCard="matMenu" class="menu-container">
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'total_amount',action:'sort',order:'asc'})">
                        <span style="font-size: 20px; margin-right: 10px;">&#8593;</span>
                        <span class="menu-label">Sort Ascending</span>
                    </button>
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'total_amount',action:'sort',order:'desc'})">
                        <span style="font-size: 20px;margin-right: 10px;">&#8595;</span>
                        <span class="menu-label">Sort Descending</span>
                    </button>
                </mat-menu>
            </div>
            <div class="table-list-col header sortable col-ordertime" [matMenuTriggerFor]="menuOrderTime">
                <div class="col-content"
                    [ngClass]="{'sort-icon-desc':sort.order=='desc' && sort.column=='order_time', 'sort-icon-asc':sort.order=='asc'  && sort.column=='order_time'}">
                    Order Time</div>
                <div class="col-options">::</div>
                <mat-menu #menuOrderTime="matMenu" class="menu-container">
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'order_time',action:'sort',order:'asc'})">
                        <span style="font-size: 20px; margin-right: 10px;">&#8593;</span>
                        <span class="menu-label">Sort Ascending</span>
                    </button>
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'order_time',action:'sort',order:'desc'})">
                        <span style="font-size: 20px;margin-right: 10px;">&#8595;</span>
                        <span class="menu-label">Sort Descending</span>
                    </button>
                </mat-menu>
            </div>
            <div class="table-list-col header sortable col-deviverytime" [matMenuTriggerFor]="menuDeliveryTime">
                <div class="col-content"
                    [ngClass]="{'sort-icon-desc':sort.order=='desc' && sort.column=='delivery_datetime', 'sort-icon-asc':sort.order=='asc'  && sort.column=='delivery_datetime'}">
                    Delivery Time</div>
                <div class="col-options">::</div>
                <mat-menu #menuDeliveryTime="matMenu" class="menu-container">
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'delivery_datetime',action:'sort',order:'asc'})">
                        <span style="font-size: 20px; margin-right: 10px;">&#8593;</span>
                        <span class="menu-label">Sort Ascending</span>
                    </button>
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'delivery_datetime',action:'sort',order:'desc'})">
                        <span style="font-size: 20px;margin-right: 10px;">&#8595;</span>
                        <span class="menu-label">Sort Descending</span>
                    </button>
                </mat-menu>
            </div>
            <div class="table-list-col header sortable col-status" [matMenuTriggerFor]="menuStatus">
                <div class="col-content"
                    [ngClass]="{'sort-icon-desc':sort.order=='desc' && sort.column=='display_text', 'sort-icon-asc':sort.order=='asc'  && sort.column=='display_text'}">
                    Status</div>
                <div class="col-options">::</div>
                <mat-menu #menuStatus="matMenu" class="menu-container">
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'display_text',action:'sort',order:'asc'})">
                        <span style="font-size: 20px; margin-right: 10px;">&#8593;</span>
                        <span class="menu-label">Sort Ascending</span>
                    </button>
                    <button mat-menu-item class="menu-item"
                        (click)="colOptionSelected({column:'display_text',action:'sort',order:'desc'})">
                        <span style="font-size: 20px;margin-right: 10px;">&#8595;</span>
                        <span class="menu-label">Sort Descending</span>
                    </button>
                </mat-menu>
            </div>
            <!-- <div class="table-list-col header col-option">
            </div> -->
        </div>

        <div class="table-list-row-container order-row-container">
            <ng-container *ngIf="orderList.length>0;then ordersList else noData"></ng-container>
            <ng-template #ordersList>
                <div *ngFor="let order of orderList;  let i=index;" class="table-list-row data-row"
                    (click)="detailView(order.order_id)"
                    [ngStyle]="{'background-color':order.order_id === SelectedOrderId   ? '#d5d4e8' : '' }">

                    <!-- <div class="table-list-col data col-sl">
                        <div class="col-content"></div>
                    </div> -->
                    <div class="table-list-col data col-invoice">
                        <div class="col-content">{{order.invoice}}</div>
                    </div>
                    <div class="table-list-col data col-cusname">
                        <div class="col-content">{{ order.customer_name}} </div>
                    </div>
                    <div class="table-list-col data col-amt">
                        <div class="col-content" style="text-align: right;">{{ order.total_amount | currency}}</div>
                    </div>
                    <div class="table-list-col data col-ordertime">
                        <div class="col-content">{{ order.order_time | date:'MMM d, y, h:mm a'}}</div>
                    </div>
                    <div class="table-list-col data col-deviverytime">
                        <div class="col-content">{{order.delivery_date + 'T' + order.delivery_time | date:'MMM d, y,
                            h:mm a' }}</div>
                    </div>
                    <div class="table-list-col data col-status">
                        <div class="col-content">

                            <span class="status-cell order-{{order.status}}"
                                [style.background-color]="order.bg_color" [style.color]="order.fg_color">
                                {{ order.display_text}} </span>
                        </div>
                    </div>


                </div>
            </ng-template>
            <ng-template #noData>
                <div class="nodata"> No Records Found.</div>
            </ng-template>

        </div>
    </div>
</div>