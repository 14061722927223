import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderViewComponent } from '../order-view/order-view.component'; 
import { OrderListComponent } from '../order-list/order-list.component';

@Component({
  selector: 'app-order-export',
  templateUrl: './order-export.component.html',
  styleUrls: ['./order-export.component.scss']
})
export class OrderExportComponent implements OnInit {


  orderExportDataOption: any[] = [{ label: 'Order Summary', value: 'all' }, { label: 'Item List', value: 'items' }];
  selectedValue: any = this.orderExportDataOption[0].value;

  constructor(private dialogRef: MatDialogRef<OrderExportComponent>) { }

  ngOnInit(): void {
  }
  actionCancel() {
    this.dialogRef.close();
  }

}
