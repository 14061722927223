import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { UserAddComponent } from './modules/user/user-add/user-add.component';
import { UserEditComponent } from './modules/user/user-edit/user-edit.component';
// import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserSearchComponent } from './modules/user/components/user-search/user-search.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSelectModule } from '@angular/material/select';
import { PaginatorComponent } from './common/components/paginator/paginator.component';
import { MatChipsModule } from '@angular/material/chips';
import { UserLoginComponent } from './modules/user/user-login/user-login.component';
import { MatCardModule } from '@angular/material/card';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './common/config/interceptors/auth.interceptor';
import { OrderListComponent } from './modules/order/order-list/order-list.component';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { ReversePipe } from './pipes/reverse.pipe';

import { OrderSearchComponent } from './modules/order/components/order-search/order-search.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { OrderExportComponent } from './modules/order/order-export/order-export.component';
import { MatRadioModule } from '@angular/material/radio';
import { OrderViewComponent } from './modules/order/order-view/order-view.component';
import { MatTableModule } from '@angular/material/table';
import { PauseOrdersListComponent } from './modules/pause-order/pause-orders-list/pause-orders-list.component';
import { PauseOrderConfirmComponent } from './modules/pause-order/pause-order-confirm/pause-order-confirm.component';
import { PauseOrderResumeComponent } from './modules/pause-order/pause-order-resume/pause-order-resume.component';
import { StockListComponent } from './modules/stock/stock-list/stock-list.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmationComponent } from './common/services/confirmation/confirmation.component';
import { CanDeactivateGuard } from './common/services/can-deactivate.guard';
import { OutofstockExportComponent } from './modules/stock/outofstock-export/outofstock-export.component';
import { ConfirmDialogComponent } from './common/services/confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { CalendarComponent,HolidayEditPopUp } from './modules/calendar/calendar.component';
import { MonthsCustomPipe } from "./pipes/month.pipe";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ToastrModule } from 'ngx-toastr';




@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    // UserListComponent,
    UserAddComponent,
    UserEditComponent,
    UserSearchComponent,
    PaginatorComponent,
    UserLoginComponent,
    OrderListComponent,
    OrderStatusPipe,
    ReversePipe,
    OrderSearchComponent,
    OrderExportComponent,
    OrderViewComponent,
    PauseOrdersListComponent,
    PauseOrderConfirmComponent,
    PauseOrderResumeComponent,
    StockListComponent,
    ConfirmationComponent,
    OutofstockExportComponent,
    ConfirmDialogComponent,
    DashboardComponent,
    CalendarComponent,
    HolidayEditPopUp,
    MonthsCustomPipe,
  
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    OverlayModule,
    MatSelectModule,
    MatCardModule,
    HttpClientModule,
    // AngularWebStorageModule,
    MatDatepickerModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatRadioModule, MatTableModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    MatSnackBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }, { provide: MAT_DATE_LOCALE, useValue: 'en-NZ' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
