<div class="pop-up-export">
  <div class="contaner">
    <div>
      <!-- <h2 class="title-class" mat-dialog-title>Export Orders</h2> -->
      <h2>Export Orders</h2>
    </div>
    <div (click)="actionCancel()">
      <span class="closeIconExport">
        X
      </span>
    </div>
  </div>
  <hr>
  <div>
    <span class="option-title">Select export type</span>
    <div class="option-container">
      <mat-radio-group [(ngModel)]="selectedValue" class="grp-esport-type" class="option-radio-group">
        <div *ngFor="let option of orderExportDataOption">
          <mat-radio-button [value]="option.value" class="rado-button-ele">
            {{option.label}}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

  </div>
  <div class="confirm-btn">
    <button mat-raised-button color="primary" class="button-cancel" (click)="actionCancel()">Cancel</button>
    <button mat-raised-button color="primary" class="button-ok" [mat-dialog-close]="selectedValue">Export</button>
  </div>
</div>