

import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { StockApiService } from '../stock-api.service';
import  moment from 'moment';

import { Moment } from 'moment';

import { NgxSpinnerService } from 'ngx-spinner';
// import { CanDeactivateState, ComponentCanDeactivate } from 'src/app/common/services/can-deactivate.guard';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { OutofstockExportComponent } from '../outofstock-export/outofstock-export.component';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { StorageService } from '../../../common/services/storage.service';
import { OnActionExport } from '../../../side-nav/side-nav.component';
import { SideNavService } from '../../../side-nav/side-nav.service';
import { SnackBarService } from '../../../common/services/snack-bar.service';
import { NotificationService } from '../../../common/services/notification.service';
import { ConfirmDialogComponent } from '../../../common/services/confirm-dialog/confirm-dialog.component';

export interface Bank {
  sale_item_id: string;
  name: string;
}
export interface Category {
  id: string;
  name: string;
}

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss'],
  encapsulation: ViewEncapsulation.None 

})
//ComponentCanDeactivate
export class StockListComponent implements OnInit,  OnActionExport {
  dialogReff: MatDialogRef<ConfirmDialogComponent>| null = null;

  protected banks: Bank[] = [];
  protected categories: Category[] = [];
  itemCategory: any;
  public selectedItemcategory: any;
  saleitemList!: any[];
  shopId: any;
  outOfStock!: any[];
  currentDate: Date;
  navigationStatus!: boolean;

  readonly formGroup = this.formBuilder.group({
    dateFrom: ['', [Validators.required]],
   dateTo: ['', [Validators.required]]
  });

  /** control for the selected bank */
  public saleitemCtrl: FormControl = new FormControl();
  public categoryCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public saleitemFilterCtrl: FormControl = new FormControl();
  public categoryFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredsaleitems: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  public filteredCategory: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);


  @ViewChild('singleSelect') singleSelect!: MatSelect;
  @ViewChild('categorySelect') categorySelect!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  saleItem!: any[];
  userId: any;
  totalStockcount: any;
  disabledStatus: boolean;
  hasError!: boolean;
  errorId: any;
  constructor(private stockApiService: StockApiService,
    private sideNavService: SideNavService,
    readonly formBuilder: FormBuilder,
    readonly router: Router,
    private storage: StorageService,
    private snackBarService: SnackBarService,
    private notifyService: NotificationService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog) {
      this.disabledStatus = true;
      if(this.formGroup.get("saleitemCtrl")===null){
        this.disabledStatus = true;
      }else{
        this.disabledStatus = false;
      }
     

    sideNavService.set(this, "Set Out Of Stock",true);
    const user = JSON.parse(this.storage.loadFromSession('user'));
    this.shopId = user.shop_id;
    

    this.loadData();

    this.currentDate = new Date();
    this.userId = user.user_id;
    //this.navigationStatus = true;
   

    // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
   // window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;

    // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
    // router.events.pipe(
    //   filter(e => e instanceof NavigationEnd),
    //   tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false)
    // ).subscribe();

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    
  }

  /**
   * Loads the data
   */
  loadData(){
    this.loadItemCategory();
    this.loadOutOfStockItems();
  }
  /**
  * list all sale items based on selected categoryid
  * 
  */
  itmCategoryChange($event:any) { // console.log($event);
    this.selectedItemcategory = $event.value.id;
    //this.categoryCtrl.setValue($event.value.id);
    this.loadSaleitems();
  }
  /**
 * list all outof stock items
 * 
 */
  loadOutOfStockItems() {
    var cdate = moment(new Date()).format('YYYY-MM-DD');
    var param = {
      shopId: this.shopId,
      cdate:cdate
    }
    this.spinner.show();
    this.stockApiService.getOutOfStockItems(param).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.spinner.hide();
          this.outOfStock = resp.data.outOfStock;
          this.totalStockcount = this.outOfStock.length;
          for (let i = 0; i < this.outOfStock.length; i++) {
            this.outOfStock[i]['from_date'] = moment(this.outOfStock[i]['from_date']).format('YYYY-MM-DD');
            this.outOfStock[i]['to_date'] = moment(this.outOfStock[i]['to_date']).format('YYYY-MM-DD');
            this.outOfStock[i]['new_item'] = "1";
            this.outOfStock[i]['userId'] = this.userId;
            this.outOfStock[i]['cdate']=cdate;
            
          }
        } else {
          this.spinner.hide();
          this.outOfStock = [];
          this.totalStockcount = 0;
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.notifyService.showError("Failed to list out of stock items.","");
      }

    );

  }

  /**
  * list item category
  * 
  */
  loadItemCategory() {
    this.stockApiService.getItemCategory().subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.itemCategory = resp.data.categoryList;
          this.categoryCtrl.value == undefined
          var seletedCat=this.itemCategory.find((e:any)=> e.id==this.selectedItemcategory)
          if(seletedCat == undefined) 
            seletedCat=resp.data.categoryList[0];
          this.categoryCtrl.setValue(seletedCat);
          this.filteredCategory.next(this.itemCategory.slice());
          this.selectedItemcategory = seletedCat.id;
          this.loadSaleitems();
        } else{
          if(resp.status !== '1016')
            this.notifyService.showError(resp.message,'');
        }
      },
      (error: any) => {
        this.notifyService.showError("Failed to list categories.","");
        
      }

    );

  }

  /**
  * list all sale items based on selected category
  * 
  */
  loadSaleitems() {
    var catId = this.selectedItemcategory;
    var cdate = moment(new Date()).format('YYYY-MM-DD');
    var param = {
      shopId: this.shopId,
      categoryId: catId,
      cdate:cdate

    }
    this.spinner.show();
    this.stockApiService.getSaleitems(param).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.saleItem = resp.data.saleitemList;
          // this.saleitemCtrl.setValue(this.saleItem[0]);
          this.filteredsaleitems.next(this.saleItem.slice());
          this.spinner.hide();
          if (this.saleItem.length > 0) {
            this.disabledStatus = false;
            if(this.formGroup.get("saleitemCtrl")===null){ 
              this.disabledStatus = true;
            }else{
              this.disabledStatus = false;
            }
          }else{
            this.disabledStatus = true;
          }

        } else{
          this.spinner.hide();
          this.saleItem = [];
          this.disabledStatus = true;
          this.filteredsaleitems.next(this.saleItem.slice());
          if(resp.status !== '1016')
            this.notifyService.showError(resp.message,"");
        }
      },
      (error: any) => {
        this.notifyService.showError("Failed to list sale items.","");
      }

    );
  }
  /**
  * export outofstock items
  * 
  */
  onActionExport(): void {
    const dialogRef = this.dialog.open(OutofstockExportComponent, {
      disableClose: true,
      autoFocus: false,
      width: "32%",
      height: "310px",
    });


  }

  onChange($event:any) {
    var arrsize = Object.keys($event.value).length; 
    if( arrsize>0){ 
      this.disabledStatus = false;
    }else{
      this.disabledStatus = true;
    }
  } 

  /**
  * add out of stock items to the  gridlist
  * 
  */
  addOutOfStock() {
   
    var saleitemId = this.saleitemCtrl.value.sale_item_id;
    var cdate = moment(new Date()).format('YYYY-MM-DD');
    
    if (saleitemId !== undefined) {
      var newItem = {
        id: 0,
        sale_item_id: this.saleitemCtrl.value.sale_item_id,
        sale_item_name: this.saleitemCtrl.value.name,
        category_name: this.saleitemCtrl.value.category_name,
        shop_id: this.shopId,
        from_date: this.currentDate,
        to_date: this.currentDate,// moment(cdate).add(1,'days').add(-1,'minutes').format('YYYY-MM-DD HH:mm:ss'),
        is_deleted: "0",
        new_item: "0",
        cdate:cdate
      };

      var saleitemStatus = this.saleitemExists(this.saleitemCtrl.value.sale_item_id);
      if (saleitemStatus == false) {
        var conformationMsg = "OUT OF STOCK";
        var contentMsg = "Would you like to set " + this.saleitemCtrl.value.name + " to out of stock?";
        this.dialogReff = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
        });
        this.dialogReff.componentInstance.confirmMessage = conformationMsg;
        this.dialogReff.componentInstance.contentMessage = contentMsg;
        this.dialogReff.afterClosed().subscribe(result => {
          if (result) {
            this.outOfStock.unshift(newItem);
            //this.navigationStatus = false;
            this.RemoveElementFromArray(saleitemId);
            this.saleitemCtrl.setValue('');
            if (this.saleItem.length > 0) { 
              this.disabledStatus = false;
            } else {
              this.disabledStatus = true;
            }
            // .........Save Section...........
            this.hasError = false;
            var cdate = moment(new Date()).format('YYYY-MM-DD');
            if (this.outOfStock.length > 0) {
              var stockData = this.outOfStock;
              for (let i = 0; i < stockData.length; i++) {
        
                // const dateFrom = moment(this.outOfStock[i]['from_date']).format('YYYY-MM-DD');
                // const dateTo = moment(this.outOfStock[i]['to_date']).format('YYYY-MM-DD');
                // var currDate = moment(new Date()).format('YYYY-MM-DD');
              
                stockData[i]['from_date'] = moment(this.outOfStock[i]['from_date']).format('YYYY-MM-DD 00:00:00');//new Date(this.outOfStock[i]['from_date']);
                stockData[i]['to_date'] = moment(this.outOfStock[i]['to_date']).format('YYYY-MM-DD 23:59:59');
                stockData[i]['userId'] = this.userId;
                stockData[i]['cdate']=cdate;
                this.errorId = '';
        
              }
              
                this.saveOutOfStock(stockData);
              
        
            }



           //...............end.................
          }
          this.dialogReff = null;
        });

        
      }
      if (this.saleItem.length > 0) { 
        this.disabledStatus = false;
      } else {
        this.disabledStatus = true;
      }
    } else { 
      this.notifyService.showError("Please select any item from the list.","");
    }

  }
  /**
  * remove the saleitems from item drop down,it is already listed on grid
  * 
  */

  RemoveElementFromArray(saleitemId:any) {
    this.saleItem.forEach((value, index) => {
      if (value.sale_item_id === saleitemId) {
        this.saleItem.splice(index, 1); //delete an element from an array
      }

    });
  }

  /**
  * check the saleitem,it is already exist in outofstock list
  * 
  */

  saleitemExists(sale_itemId:any) {
    return this.outOfStock.some(function (el) {
      return el.sale_item_id === sale_itemId;
    });

  }


  /**
   * delete an item from the outofstock list
   * 
   */
  // deleteItem(index:any) {
  //   var deleteMsg = "Are you sure want to delete the item from the list?";
  //     this.dialogReff = this.dialog.open(ConfirmDialogComponent, {
  //       disableClose: true,
  //     });
  //     this.dialogReff.componentInstance.confirmMessage = deleteMsg;
  //   if (this.outOfStock[index].new_item === "0") {
  //     var AddToSaleitemList = {
  //       sale_item_id: this.outOfStock[index].sale_item_id,
  //       name: this.outOfStock[index].sale_item_name,
  //       category_name: this.outOfStock[index].category_name
  //     }
  //     this.dialogReff.afterClosed().subscribe(result => {
  //       if (result) {
  //         this.outOfStock.splice(index, 1); // delete from out of stock list
  //         this.saleItem.unshift(AddToSaleitemList); // add saleitem list
  //       }
  //       this.dialogReff = null;
  //     });
     
  //     if (this.saleItem.length > 0) {
  //       this.disabledStatus = false;
  //     } else {
  //       this.disabledStatus = true;
  //     }
  //   } else if (this.outOfStock[index].new_item === "1") {
      
      
  //     this.dialogReff.afterClosed().subscribe(result => {
  //       if (result) {
  //         this.outOfStock[index].is_deleted = "1";
  //         this.outOfStock[index].to_date = this.currentDate;
  //         var cdate = moment(new Date()).format('YYYY-MM-DD');
  //         this.outOfStock[index]['from_date'] = moment(this.outOfStock[index]['from_date']).format('YYYY-MM-DD');
  //         this.outOfStock[index]['to_date'] = moment(this.outOfStock[index]['to_date']).format('YYYY-MM-DD');
  //         this.outOfStock[index]['userId'] = this.userId;
  //         this.outOfStock[index]['cdate']=cdate;
  //         this.saveOutOfStock(this.outOfStock);
          
  //       }else{
  //         this.outOfStock[index].is_deleted = "0";
  //         this.outOfStock[index]['from_date'] = moment(this.outOfStock[index]['from_date']).format('YYYY-MM-DD');
  //         this.outOfStock[index]['to_date'] = moment(this.outOfStock[index]['to_date']).format('YYYY-MM-DD');
  //         this.outOfStock[index]['userId'] = this.userId;
  //         this.outOfStock[index]['cdate']=cdate;
  //       }
  //       this.dialogReff = null;
  //     });
      
  //   }
   
  //   //this.navigationStatus = false;
  // }
  deleteItem(index: number) {
    const deleteMsg = "Are you sure you want to delete the item from the list?";
    this.dialogReff = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
    });
    this.dialogReff.componentInstance.confirmMessage = deleteMsg;

    this.dialogReff.afterClosed().subscribe(result => {
      if (result) {
        if (this.outOfStock[index].new_item === "0") {
          const AddToSaleitemList = {
            sale_item_id: this.outOfStock[index].sale_item_id,
            name: this.outOfStock[index].sale_item_name,
            category_name: this.outOfStock[index].category_name,
          };
          this.outOfStock.splice(index, 1); // Delete from out of stock list
          this.saleItem.unshift(AddToSaleitemList); // Add to sale item list
        } else if (this.outOfStock[index].new_item === "1") {
          this.outOfStock[index].is_deleted = "1";
          this.outOfStock[index].to_date = moment(new Date()).format('YYYY-MM-DD');
          const cdate = moment(new Date()).format('YYYY-MM-DD');
          this.outOfStock[index].from_date = moment(this.outOfStock[index].from_date).format('YYYY-MM-DD');
          this.outOfStock[index].to_date = moment(this.outOfStock[index].to_date).format('YYYY-MM-DD');
          this.outOfStock[index].userId = this.userId;
          this.outOfStock[index].cdate = cdate;
          this.saveOutOfStock(this.outOfStock); // Assuming you have a saveOutOfStock function
        }
        this.disabledStatus = this.saleItem.length > 0 ? false : true;
      }
      this.dialogReff = null; // Reset dialog reference
    });
  }

  /**
    * validate fromdate and todate when saving the outofstock data
    * 
    */
  onActionSave1(): void {
    var flag = 0;
    this.hasError = false;
    var cdate = moment(new Date()).format('YYYY-MM-DD');
    console.log(this.outOfStock);
    if (this.outOfStock.length > 0) {
      var stockData = this.outOfStock;
      for (let i = 0; i < stockData.length; i++) {

        const dateFrom = moment(this.outOfStock[i]['from_date']).format('YYYY-MM-DD');
        const dateTo = moment(this.outOfStock[i]['to_date']).format('YYYY-MM-DD');
        var currDate = moment(new Date()).format('YYYY-MM-DD');
        //console.log(dateFrom + '::' + dateTo + '..' + currDate);
        if (dateFrom > currDate) {
          this.notifyService.showError("From date must not be earlier than today", this.outOfStock[i]['sale_item_name']);
          this.hasError = true;
          this.errorId = this.outOfStock[i]['sale_item_id'];
          flag = 1;
          break;
        }
        if (dateTo < dateFrom) {
          this.notifyService.showError("To date should be greater than from date", this.outOfStock[i]['sale_item_name']);
          this.hasError = true;
          this.errorId = this.outOfStock[i]['sale_item_id'];
          flag = 1;
          break;
        }
        stockData[i]['from_date'] = moment(this.outOfStock[i]['from_date']).format('YYYY-MM-DD');//new Date(this.outOfStock[i]['from_date']);
        stockData[i]['to_date'] = moment(this.outOfStock[i]['to_date']).format('YYYY-MM-DD');
        stockData[i]['userId'] = this.userId;
        stockData[i]['cdate']=cdate;
        
        this.errorId = '';

      }
      if (flag == 0) {
        this.saveOutOfStock(stockData);
      }

    }

  }
  
  /**
  * save outofstockitems to db.
  * 
  */
  saveOutOfStock(stockData:any) {

    this.stockApiService.saveOutOfStock(stockData).subscribe(
      (resp: any) => {
        if (resp.status == 0) {
          this.outOfStock = resp.data.outOfStock;
          this.totalStockcount = this.outOfStock.length;
          for (let i = 0; i < this.outOfStock.length; i++) {
            this.outOfStock[i]['from_date'] = new Date(this.outOfStock[i]['from_date']);
            this.outOfStock[i]['to_date'] = new Date(this.outOfStock[i]['to_date']);
            this.outOfStock[i]['new_item'] = "1";
          }
          this.notifyService.showSuccess("Successfully updated",'');
          // window.location.reload();
          this.loadData();
          // this.loadSaleitems()

        } else {
          this.outOfStock = [];
          this.totalStockcount = 0;
          if(resp.status !== '1016')
            this.notifyService.showError(resp.message,"");
          // window.location.reload();
          this.loadData();
        }
      },
      (error: any) => {
        this.notifyService.showError("Failed to save out of stock items.","");
      }

    );

  }


  ngOnInit() {
    // listen for search field value changes
    this.saleitemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSaleitems();
      });

      this.categoryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCategory();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  /**
   * Sets the initial value after the filteredsaleitems are loaded initially
   */
  protected setInitialValue() {
    this.filteredsaleitems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredsaleitems are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.sale_item_id === b.sale_item_id;
      });

      this.filteredCategory.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredsaleitems are loaded initially
        // and after the mat-option elements are available
        this.categorySelect.compareWith = (a: Category, b: Category) => a && b && a.id === b.id;
      });

  }
  /**
  * Method is use to filter the sale items.
  * 
  */

  protected filterSaleitems() {
    if (!this.saleItem) {
      return;
    }
    // get the search keyword
    let search = this.saleitemFilterCtrl.value;
    if (!search) {
      this.filteredsaleitems.next(this.saleItem.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredsaleitems.next(
      this.saleItem.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterCategory(){ 
    if (!this.itemCategory) {
      return;
    }
    // get the search keyword
    let search = this.categoryFilterCtrl.value;
    if (!search) {
      this.filteredCategory.next(this.itemCategory.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCategory.next(
      this.itemCategory.filter((item:any) => item.name.toLowerCase().indexOf(search) > -1)
    );

  }

  /**
    * Method is use to check the navigation status.
    * 
    */

  // canDeactivate() {
  //   return this.navigationStatus;
  // }

}